<template>
  <div class="main home generate">
    <does-exist>
      <b-container id="app">
        <b-row>
          <b-col
            ><div class="headline-main">
              <h2>
                <b-icon icon="code-square"></b-icon
                ><span>Weiterleitungen generieren</span>
              </h2>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="5">
            <b-card class="card__redirects" header="header">
              <template #header>
                <b-card-title>Angelegte Weiterleitungen</b-card-title>
                <b-card-sub-title class="mt-1"></b-card-sub-title>
              </template>
              <b-card-text class="search-bar">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-icon icon="search"></b-icon>
                  </b-input-group-prepend>

                  <b-form-input
                    type="text"
                    v-model="search"
                    placeholder="Nach URL suchen..."
                  ></b-form-input>
                  <!-- 
                <b-input-group-append>
                  <b-button>Suchen</b-button>
                </b-input-group-append> -->
                </b-input-group>
              </b-card-text>
              <div v-if="this.loading">
                <b-card-text>
                  <b-alert class="alert__loading" show variant="secondary"
                    ><b-spinner
                      small
                      variant="light"
                      type="grow"
                      class="mr-3"
                      label="Busy"
                    ></b-spinner
                    >Weiterleitungen werden geladen...</b-alert
                  >
                </b-card-text>
              </div>
              <div v-else>
                <b-card-text>
                  <p v-if="this.store.urlsToRedirect.length > 0">
                    Es gibt
                    <b-badge pill variant="dark">{{
                      store.urlsToRedirect.length
                    }}</b-badge>
                    URLs die noch nicht weitergeleitet wurden.
                  </p>
                  <b-list-group>
                    <TransitionGroup name="list" mode="out-in">
                      <b-list-group-item
                        v-for="urls1 in filteredList"
                        :key="urls1._id"
                        class="redirect-item"
                      >
                        <div class="url-text">
                          <span class="old-url">{{ urls1.url }}</span
                          ><br />
                          <span class="newurl-item">
                            <b-icon icon="arrow-return-right"></b-icon>
                            <span class="new-url">{{ urls1.newUrl }}</span>
                          </span>

                          <b-badge
                            v-if="checkDate(urls1)"
                            class="edited new"
                            pill
                            >NEU</b-badge
                          >

                          <b-badge
                            v-if="
                              urls1.checked.code === '301' &&
                              removeTrailingSlash(
                                urls1.checked.headers.location
                              ) !==
                                changeStagingToLiveDomain(
                                  removeTrailingSlash(urls1.newUrl)
                                )
                            "
                            class="edited border-red"
                            :class="{ disabled: checkDate(urls1) }"
                            pill
                            >{{ urls1.checked.code }}</b-badge
                          >

                          <b-badge
                            v-if="
                              urls1.checked.code === '301' &&
                              removeTrailingSlash(
                                urls1.checked.headers.location
                              ) ==
                                changeStagingToLiveDomain(
                                  removeTrailingSlash(urls1.newUrl)
                                )
                            "
                            :class="{ disabled: checkDate(urls1) }"
                            class="edited green"
                            pill
                            >{{ urls1.checked.code }}</b-badge
                          >

                          <b-badge
                            v-if="urls1.checked.code === '404'"
                            pill
                            :class="{ disabled: checkDate(urls1) }"
                            class="edited red"
                            >{{ urls1.checked.code }}</b-badge
                          >

                          <b-badge
                            v-if="urls1.checked.code === '200'"
                            pill
                            :class="{ disabled: checkDate(urls1) }"
                            class="edited normal"
                            >{{ urls1.checked.code }}</b-badge
                          >
                        </div>
                        <div class="buttons">
                          <b-button
                            class="icon-btn"
                            :id="'tooltip-delete-' + urls1._id"
                            @click="showDelRedirectModal(urls1)"
                            ><b-icon icon="trash-fill"></b-icon>
                          </b-button>

                          <b-tooltip
                            :target="'tooltip-delete-' + urls1._id"
                            triggers="hover"
                            variant="danger"
                            placement="top"
                          >
                            Weiterleitung <strong>löschen</strong>!
                          </b-tooltip>

                          <b-button
                            class="icon-btn bg-btn"
                            @click="showEditModal(urls1)"
                            :id="'tooltip-edit-' + urls1._id"
                            ><b-icon icon="pencil-fill"></b-icon
                          ></b-button>

                          <b-tooltip
                            :target="'tooltip-edit-' + urls1._id"
                            triggers="hover"
                            variant="danger"
                            placement="top"
                          >
                            Weiterleitung <strong>bearbeiten</strong>!
                          </b-tooltip>
                        </div>
                      </b-list-group-item>
                    </TransitionGroup>
                  </b-list-group>
                </b-card-text>
                <b-card-text text- v-if="this.redirectedUrls == ''">
                  <div class="empty-state">
                    <img src="../assets/empty-redirects.svg" />

                    Es sind keine Weiterleitungen angelegt.
                  </div>
                </b-card-text>
              </div>
            </b-card>

            <!-- <b-modal
            hide-footer
            class="text-dark"
            title="Weiterleitung bearbeiten"
            ref="edit-modal"
          >
            <p class="my-4 text-dark">Vertically centered modal!</p>
            Neue Url. auswählen:

            <b-form-select
              v-model="onEditRedirectTo"
              :options="items"
              value-field="url"
              text-field="url"
            ></b-form-select>

            Eigene Url eingeben
            <b-form-input v-model="onEditRedirectTo"></b-form-input>
            <b-btn class="mt-4" v-on:click="editRedirect()">
              <b-icon icon="pencil-fill" left></b-icon>Bearbeiten</b-btn
            >
          </b-modal> -->
          </b-col>

          <b-col offset-md="1" cols="12" md="6">
            <b-card class="card__generate" header="header">
              <template #header>
                <b-card-title> Redirects </b-card-title>
                <div class="meta">
                  <p
                    class="status"
                    v-if="
                      typeof projectstore.aktProject.generated !== 'undefined'
                    "
                  >
                    Zuletzt generiert am:
                    {{ genDate }}
                  </p>

                  <b-button
                    variant="primary"
                    @click="openGenerateRed()"
                    v-if="redirects == ''"
                    :class="{
                      shake: shakeAnim,
                    }"
                    >Weiterleitungen generieren</b-button
                  >
                  <b-button
                    variant="outline-primary"
                    @click="openGenerateRed()"
                    v-if="redirects != ''"
                    >Weiterleitungen generieren</b-button
                  >
                </div>
              </template>

              <p class="danger" v-if="this.countNew > 0 && this.countNew <= 1">
                <b-icon icon="arrow-repeat" class="mr-2"></b-icon>Es wurde
                {{ countNew }} Änderung in den Weiterleitungen gefunden.
              </p>
              <p class="danger" v-if="this.countNew > 1">
                <b-icon icon="arrow-repeat"></b-icon>Es wurden
                {{ countNew }} Änderungen in den Weiterleitungen gefunden.
              </p>

              <b-card-text>
                <b-list-group>
                  <b-list-group-item>
                    <b-button
                      class="copy-btn"
                      v-if="this.redirects != ''"
                      v-clipboard:copy="this.redirectscopy"
                      v-clipboard:success="this.onCopyRedirects"
                      ><b-icon icon="files"></b-icon
                    ></b-button>

                    <div class="empty-state" v-if="this.redirects == ''">
                      <img src="../assets/empty-generate.svg" />
                      Es wurden noch keine Weiterleitungen generiert.
                    </div>

                    <div v-for="item in redirects" :key="item" id="copyArea">
                      {{ item }}
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </b-container>

      <!-- ### EDIT MODAL ### -->
      <b-modal hide-footer ref="edit-modal" modal-class="modal__default">
        <template #modal-title>
          <b-icon icon="pencil-fill"></b-icon>Weiterleitung bearbeiten
        </template>
        <b-list-group class="modal__edit"
          ><b-list-group-item>
            {{ onEdit.url }}
          </b-list-group-item></b-list-group
        >
        <label> Weiterleiten nach:</label>

        <b-form-input
          v-model="onEditRedirectTo"
          autocomplete="off"
          list="my-list-id"
          placeholder="Url auswählen"
        ></b-form-input>

        <datalist id="my-list-id" v-if="this.loadingNewUrls == false">
          <option v-for="item in newUrls" :key="item._id">
            {{ item.url }}
          </option>
        </datalist>
        <datalist id="my-list-id" v-if="this.loadingNewUrls == true">
          <option>URLs werden geladen...</option>
        </datalist>
        <div class="modal-buttons">
          <b-button variant="secondary" v-on:click="closeRedirect()">
            Abbrechen</b-button
          >
          <b-button variant="primary" v-on:click="editRedirect()">
            <b-icon icon="pencil-fill"></b-icon>Speichern</b-button
          >
        </div>
      </b-modal>

      <!-- ### DELETE MODAL ### -->
      <b-modal
        hide-footer
        class="text-dark"
        ref="delete-modal"
        modal-class="modal__default"
      >
        <template #modal-title>
          <b-icon icon="trash-fill"></b-icon>Weiterleitung löschen
        </template>
        <b-list-group class="model__delete"
          ><b-list-group-item class="redirect-item">
            <span class="old-url">{{ onDelete.url }}</span
            ><br />
            <span class="newurl-item">
              <b-icon icon="arrow-return-right"></b-icon>
              <span class="new-url">{{ onDelete.newUrl }}</span>
            </span>
          </b-list-group-item></b-list-group
        >
        <div class="modal-buttons">
          <b-button
            class=""
            variant="secondary"
            v-on:click="closeDelRedirect()"
          >
            Abbrechen</b-button
          >

          <b-btn class="delete" variant="primary" v-on:click="deleteRedirect()">
            <b-icon icon="trash-fill"></b-icon>Löschen</b-btn
          >
        </div>
      </b-modal>

      <!-- ### GENERATE MODAL ### -->
      <b-modal
        hide-footer
        class="text-dark"
        ref="generate-modal"
        modal-class="modal__default modal__generate"
      >
        <template #modal-title>
          <b-icon icon="code-square"></b-icon>Weiterleitung generieren
        </template>
        <b-list-group class="model__generate">
          <p>
            Für welchen Webserver möchtest du die Weiterleitungen generieren?
          </p>
          <b-btn
            class="generate-btn"
            variant="primary"
            v-on:click="generateRed(1)"
          >
            <!-- 1 means nginx -->
            NGINX</b-btn
          >
          <b-btn
            class="generate-btn"
            variant="primary"
            v-on:click="generateRed(3)"
          >
            <!-- 1 means nginx -->
            NGINX with Parameter (beta :)</b-btn
          >
          <b-btn
            class="generate-btn"
            variant="primary"
            v-on:click="generateRed(2)"
          >
            <!-- 2 means htaccess -->
            HTACCESS</b-btn
          >
          <b-button
            class="generate-abbrechen-btn"
            variant="secondary"
            v-on:click="closeGenerateRed()"
          >
            Abbrechen</b-button
          >
        </b-list-group>
        <div class="modal-buttons"></div>
      </b-modal>

      <transition name="fade">
        <b-card class="card__forward" header="header" v-if="this.showNext">
          <template #header>
            <b-card-title variant="success"
              >Die Weiterleitungen wurden erfolgreich generiert!
            </b-card-title>
            <b-button class="close-btn" @click="closeForward()"
              ><b-icon icon="x"></b-icon
            ></b-button>
          </template>

          <b-card-text>
            Füge diese nun in deine Serverkonfiguration ein und überprüfe die
            Weiterleitungen im nächsten Schritt.
          </b-card-text>
          <b-button
            variant="primary"
            class="forward-btn"
            v-if="this.redirects != ''"
            @click="forward()"
            ><b-icon icon="check2-square"></b-icon>Zum nächsten
            Schritt</b-button
          >
        </b-card>
      </transition>
    </does-exist>
  </div>
</template>

<script>
import { projectStore } from "../store/project.js";
import { useStore } from "../store/index";
import { newUrlStore } from "../store/newUrls";
import { reactive, ref } from "@vue/composition-api";
import router from "../router";
import moment from "moment";

export default {
  name: "GenerateRed",
  props: ["project"],
  components: {},

  setup() {
    const store = useStore();
    const newUrlstore = newUrlStore();
    const projectstore = projectStore();
    const redirects = reactive([]);
    const countNew = ref("");
    const countDel = ref("");

    return {
      projectstore,
      store,
      newUrlstore,
      router,

      search: "",

      onEditRedirectTo: "",
      onEdit: "",

      onDelete: "",

      chooseServer: -1,

      redirects,
      redirectscopy: "",

      shakeAnim: false,

      countNew,
      countDel,
      showNext: false,
    };
  },

  computed: {
    urls: {
      get() {
        return this.store.urls;
      },
      set(value) {
        this.store.urls = value;
      },
    },
    newUrls() {
      return this.newUrlstore.newUrls;
    },
    redirectedUrls: {
      get() {
        return this.store.redirectedUrls;
      },
      set(value) {
        this.store.redirectedUrls = value;
      },
    },
    filteredList() {
      return this.redirectedUrls.filter((post) => {
        return post.url.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    urlsToRedirect: {
      get() {
        return this.store.urlsToRedirect;
      },
      set(value) {
        this.store.urlsToRedirect = value;
      },
    },
    loading() {
      return this.store.loadingFetchUrls;
    },
    loadingNewUrls() {
      return this.newUrlstore.loadingNewUrls;
    },
    StoreExists() {
      return this.store.exists;
    },
    genDate() {
      return moment(this.projectstore.aktProject.generated.date).format(
        "DD.MM.YYYY, HH:mm [Uhr]"
      );
    },
    alreadyCheckCode() {
      return this.projectstore.aktProject.checked != undefined;
    },
  },
  methods: {
    openGenerateRed() {
      this.$refs["generate-modal"].show();
    },

    closeGenerateRed() {
      this.$refs["generate-modal"].hide();
    },
    generateRed(server) {
      this.countNew = 0;
      if (this.store.redirectedUrls == "") {
        this.shakeAnim = true;
        setTimeout(() => {
          this.shakeAnim = false;
        }, 1500);

        this.disabled = false;
      } else {
        this.redirects = [];
        this.redirectscopy = "";

        if (server == 1) {
          this.redirectedUrls.forEach((elem) => {
            let add1 =
              "rewrite ^" +
              sanitizeURL(
                elem.url.replace(
                  sanitizeURL(this.projectstore.aktProject.aktUrl),
                  ""
                )
              );

            let add2 =
              "$ " +
              elem.newUrl.replace(
                this.projectstore.aktProject.stagingUrl,
                this.projectstore.aktProject.newUrl
              ) +
              " permanent;";

            let add = add1 + add2;

            this.redirectscopy += add + " \n";
            this.redirects.push(add);
          });
        } else if (server == 3) {
          this.redirectedUrls.forEach((elem) => {
            if (elem.url.includes("?")) {
              let add1 =
                "if ($request_uri = '" +
                sanitizeURL(
                  elem.url.replace(
                    sanitizeURL(this.projectstore.aktProject.aktUrl),
                    ""
                  )
                );

              let add2 =
                "') { \nreturn 301 " +
                elem.newUrl.replace(
                  this.projectstore.aktProject.stagingUrl,
                  this.projectstore.aktProject.newUrl
                ) +
                ";\n}";

              let add = add1 + add2;

              this.redirectscopy += add + " \n";
              this.redirects.push(add);
            } else {
              let add1 =
                "rewrite ^" +
                sanitizeURL(
                  elem.url.replace(
                    sanitizeURL(this.projectstore.aktProject.aktUrl),
                    ""
                  )
                );

              let add2 =
                "$ " +
                elem.newUrl.replace(
                  this.projectstore.aktProject.stagingUrl,
                  this.projectstore.aktProject.newUrl
                ) +
                " permanent;";

              let add = add1 + add2;

              this.redirectscopy += add + " \n";
              this.redirects.push(add);
            }
          });
        } else if (server == 2) {
          this.redirectedUrls.forEach((elem) => {
            let add1 =
              "Redirect 301 " +
              sanitizeURL(
                elem.url.replace(
                  sanitizeURL(this.projectstore.aktProject.aktUrl),
                  ""
                )
              );

            let add2 =
              " " +
              elem.newUrl.replace(
                this.projectstore.aktProject.stagingUrl,
                this.projectstore.aktProject.newUrl
              );

            let add = add1 + add2;

            this.redirectscopy += add + " \n";
            this.redirects.push(add);
          });
        }

        this.projectstore.aktProject.generated = {
          date: new Date(),
          redirects: this.redirects,
        };

        this.store.generateRed(this.projectstore.aktProject, this.redirects);
        this.showNext = true;
      }

      this.closeGenerateRed();

      function sanitizeURL(url) {
        let urlString = url;
        if (urlString.includes("https://")) {
          urlString = urlString.replace("https://", "");
        }
        if (urlString.includes("http://")) {
          urlString = urlString.replace("http://", "");
        }
        if (urlString.includes("www.")) {
          urlString = urlString.replace("www.", "");
        }

        return urlString;
      }
    },
    checkNewRedirects() {
      this.countNew = 0;
      this.countDel = 0;

      this.store.redirectedUrls.forEach((elem) => {
        if (
          moment(elem.updatedAt).isAfter(
            this.projectstore.aktProject.generated.date
          )
        ) {
          this.countNew++;
        }
      });

      // FIXME: Count deleted items.

      // this.urlsToRedirect.forEach((elem) => {
      //   this.store.redirectedUrls.forEach((redElem) => {
      //     if (elem._id == redElem._id) {
      //       if (
      //         moment(elem.updatedAt).isAfter(
      //           this.projectstore.aktProject.generated.date
      //         )
      //       ) {
      //         this.countDel++;
      //       }
      //     }
      //   });
      // });
    },
    onCopyRedirects() {
      this.makeToast(
        this.projectstore.aktProject.proj_id,
        "Die Redirects wurden erfolgreich kopiert."
      );
    },

    onErrorCopyRedirects() {
      this.makeToast(
        this.projectstore.aktProject.proj_id,
        "Die Redirects konnten nicht kopiert werden."
      );
    },
    closeForward() {
      this.showNext = false;
    },
    forward() {
      this.showNext = true;
      this.router.push(
        "/project/" + this.projectstore.aktProject.proj_id + "/ueberpruefen"
      );
    },

    showEditModal(url) {
      this.onEdit = url;
      this.onEditRedirectTo = url.newUrl;
      this.$refs["edit-modal"].show();
    },

    editRedirect() {
      // loop through all selected urls
      this.urls.forEach((elem) => {
        if (this.onEdit._id === elem._id) {
          // set element new url
          elem.newUrl = this.onEditRedirectTo;
          elem.updatedAt = new Date();

          // loop through main urls list
          this.redirectedUrls.forEach((item) => {
            // select selected urls in main url list
            if (item._id === elem._id) {
              // update main url list
              item.newUrl = elem.newUrl;
              this.store.updateDB(this.project, elem._id);
            }
          });
        }
      });
      this.$refs["edit-modal"].hide();
      this.onEditRedirectTo = "";
      this.makeToast(
        this.projectstore.aktProject.proj_id,
        "Die Weiterleitung wurde erfolgreich geändert."
      );
      this.checkNewRedirects();
    },

    closeRedirect() {
      this.$refs["edit-modal"].hide();
    },

    showDelRedirectModal(url) {
      this.onDelete = url;
      this.$refs["delete-modal"].show();
    },

    closeDelRedirect() {
      this.$refs["delete-modal"].hide();
    },

    deleteRedirect() {
      // loop through all selected urls
      this.urls.forEach((elem) => {
        if (this.onDelete._id === elem._id) {
          // set element new url
          elem.newUrl = "";
          elem.checked = [];
          elem.updatedAt = new Date();

          let i = 0;
          // loop through main urls list
          this.redirectedUrls.forEach((item) => {
            // select selected urls in main url list
            if (item._id === elem._id) {
              // update main url list

              this.redirectedUrls.splice(i, 1);

              this.store.updateDB(this.project, elem._id);
            }
            i++;
          });
          this.store.urlsToRedirect.push(elem);
        }
      });
      this.$refs["delete-modal"].hide();
      this.makeToast(
        this.projectstore.aktProject.proj_id,
        "Die Weiterleitung wurde erfolgreich gelöscht."
      );

      this.projectstore.aktProject.open = this.store.urlsToRedirect.length;
      this.store.updateConfigDB(this.projectstore.aktProject);
    },

    checkDate(url) {
      if (
        typeof url.updatedAt != "undefined" &&
        typeof this.projectstore.aktProject.generated != "undefined"
      ) {
        return moment(url.updatedAt).isAfter(
          this.projectstore.aktProject.generated.date
        );
      } else {
        return false;
      }
    },

    removeTrailingSlash(url) {
      // console.log(url);
      if (url !== undefined && url != "") {
        // console.log("removeTrailingSlash", url.replace(/\/?(\?|#|$)/, "$1"));
        return url.replace(/\/?(\?|#|$)/, "$1");
      } else {
        return url;
      }
    },

    changeStagingToLiveDomain(url) {
      // console.log(
      //   "changeURL",
      //   url.replace(
      //     this.projectstore.aktProject.stagingUrl,
      //     this.projectstore.aktProject.newUrl
      //   )
      // );
      return url.replace(
        this.projectstore.aktProject.stagingUrl,
        this.projectstore.aktProject.newUrl
      );
    },
  },

  async created() {
    if (this.project !== this.projectstore.aktProject.proj_id) {
      this.store.loadingFetchUrls = true;
      await this.projectstore.fetchProjects();
      await this.projectstore.fetchAktConfig(this.project);

      await this.store.fetchUrls(this.project);
      await this.newUrlstore.fetchNewUrlsFirst(
        this.projectstore.aktProject.stagingUrl,
        this.project
      );
    }

    if (this.projectstore.aktProject.generated) {
      this.redirects = this.projectstore.aktProject.generated.redirects;
      this.redirectscopy = "";
      this.redirects.forEach((elem) => {
        this.redirectscopy += elem + " \n";
      });
      this.checkNewRedirects();
    }

    // await this.generateRed();
  },
};
</script>
