import http from "../axios-connect";
class ProjectService {
  getAll() {
    return http.get(`/config`);
  }
  create(data) {
    return http.post("/config/create", data);
  }
  updateOne(id, data) {
    return http.post(`/config/${id}`, data);
  }
  delete(id) {
    return http.delete(`/config/${id}`);
  }
}
export default new ProjectService();
