<template>
  <div v-if="this.StoreExists == false">
    <slot></slot>
  </div>
  <div v-else>
    <error-state></error-state>
  </div>
</template>

<script>
import { useStore } from "../store/index.js";
import { projectStore } from "../store/project.js";

export default {
  setup() {
    const projectstore = projectStore();
    const store = useStore();

    return {
      projectstore,
      store,
    };
  },

  computed: {
    StoreExists() {
      return this.store.exists;
    },
  },
  methods: {},
};
</script>
