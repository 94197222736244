import { defineStore } from "pinia";
import { errorStore } from "./error";

import router from "../router";

export const userStore = defineStore("users", {
  state: () => ({
    isAuth: false,
    aktUser: null,
    token: "banana",
    user: { username: "teufels", password: "teufels" },
    returnUrl: "",
  }),
  getters: {
    isAuthenticated() {
      return this.isAuth;
    },
  },

  actions: {
    async authUser(username, password) {
      const errorstore = errorStore();

      if (username == this.user.username && password == this.user.password) {
        errorstore.errorOnLogin = "";
        localStorage.setItem("auth", JSON.stringify(this.token));
        router.push(this.returnUrl || "/").catch((err) => {
          //
        });
      } else {
        console.log("noo");
        errorstore.errorOnLogin = "Logindaten falsch!";
      }
    },

    authOnReload() {
      if (localStorage.getItem("auth") == JSON.stringify(this.token)) {
        this.isAuth = true;
      }
    },

    logout() {
      this.aktUser = null;
      this.isAuth = false;
      localStorage.removeItem("auth");
      router.push("/login");
    },
  },
});
