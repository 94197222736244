<template>
  <div class="main home">
    <does-exist>
      <b-container id="app">
        <b-row>
          <b-col
            ><div class="headline-main">
              <h2>
                <b-icon icon="check-square"></b-icon
                ><span>Weiterleitungen überprüfen</span>
              </h2>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card header="header" class="card__control">
              <template #header>
                <b-card-title class="my-0 py-0 text-dark"
                  >Angelegte Weiterleitungen</b-card-title
                >
                <div class="meta">
                  <p class="status" v-if="projectstore.aktProject.checked">
                    Zuletzt geprüft am:
                    {{ checkedDate }}
                  </p>
                  <b-button
                    :disabled="loadingControl"
                    v-if="!projectstore.aktProject.checked"
                    variant="primary"
                    @click="controlRed()"
                    ><b-spinner
                      v-if="loadingControl"
                      small
                      type="grow"
                      class="mr-3"
                    ></b-spinner
                    >Überprüfung starten</b-button
                  >

                  <b-button
                    v-if="projectstore.aktProject.checked"
                    :disabled="loadingControl"
                    variant="outline-primary"
                    @click="controlRed()"
                    ><b-spinner
                      v-if="loadingControl"
                      small
                      type="grow"
                      class="mr-2"
                    ></b-spinner
                    >Überprüfung erneut starten</b-button
                  >
                </div>
              </template>

              <div v-if="projectstore.aktProject.checked">
                <b-card-text v-if="this.redirectedUrls == '' && !this.updates">
                  <div class="empty-state">
                    <img src="../assets/empty-redirects.svg" />

                    Es sind keine Weiterleitungen zum Prüfen angelegt.
                  </div>
                </b-card-text>
                <b-card-text v-if="!this.updates">
                  <b-list-group
                    ><div
                      v-for="urls1 in this.store.getRedirectedUrls"
                      :key="urls1._id"
                    >
                      <b-list-group-item
                        v-if="urls1.checked.code"
                        class="redirect-item"
                      >
                        <div class="redirect-item-url">
                          <span class="old-url">{{ urls1.url }}</span
                          ><br />
                          <b-icon icon="arrow-return-right"></b-icon>
                          <span class="new-url">{{
                            changeStagingToLiveDomain(urls1.newUrl)
                          }}</span>
                          <br />
                          <br />
                          <span
                            class="red-color new-url"
                            v-if="
                              urls1.checked.code === '301' &&
                              removeTrailingSlash(
                                urls1.checked.headers.location
                              ) !==
                                changeStagingToLiveDomain(
                                  removeTrailingSlash(urls1.newUrl)
                                )
                            "
                          >
                            <strong>Leitet nach: </strong
                            >{{ urls1.checked.headers.location }}
                          </span>
                        </div>

                        <div class="buttons">
                          <div class="statuscode" v-if="urls1.checked.headers">
                            <span v-if="urls1.checked.headers.count > 0">
                              {{ urls1.checked.headers.count }} Weiterleitungen
                            </span>
                            <span v-if="!urls1.checked.headers.count">
                              0 Weiterleitungen
                            </span>
                          </div>
                          <div
                            class="statuscode border-red"
                            v-if="
                              urls1.checked.code === '301' &&
                              removeTrailingSlash(
                                urls1.checked.headers.location
                              ) !==
                                changeStagingToLiveDomain(
                                  removeTrailingSlash(urls1.newUrl)
                                )
                            "
                          >
                            <!-- {{
                              removeTrailingSlash(
                                urls1.checked.headers.location
                              )
                            }}
                            <br />
                            {{
                              changeStagingToLiveDomain(
                                removeTrailingSlash(urls1.newUrl)
                              )
                            }} -->
                            {{ urls1.checked.code }}
                          </div>
                          <div
                            class="statuscode green"
                            v-if="
                              urls1.checked.code === '301' &&
                              removeTrailingSlash(
                                urls1.checked.headers.location
                              ) ==
                                changeStagingToLiveDomain(
                                  removeTrailingSlash(urls1.newUrl)
                                )
                            "
                          >
                            {{ urls1.checked.code }}
                          </div>
                          <div
                            class="statuscode red"
                            v-if="urls1.checked.code === '404'"
                          >
                            {{ urls1.checked.code }}
                          </div>
                          <div
                            class="statuscode"
                            v-if="urls1.checked.code === '200'"
                          >
                            {{ urls1.checked.code }}
                          </div>
                          <!-- <b-button
                          class="icon-btn"
                          @click="showDelRedirectModal(urls1)"
                          ><b-icon icon="trash-fill"></b-icon>
                        </b-button> -->
                          <!-- <b-button
                            class="icon-btn"
                            @click="showEditModal(urls1)"
                            ><b-icon icon="pencil-fill"></b-icon
                          ></b-button> -->
                        </div>
                      </b-list-group-item>
                    </div>
                  </b-list-group>
                </b-card-text>
                <b-card-text v-if="this.updates">
                  <div class="empty-state empty-state--updates">
                    <img src="../assets/changes_detected.svg" />

                    Es wurden Änderungen an den Weiterleitungen erkannt.
                  </div>
                  <b-button
                    v-if="projectstore.aktProject.checked"
                    :disabled="loadingControl"
                    variant="primary"
                    class="updates"
                    @click="controlRed()"
                    ><b-spinner
                      v-if="loadingControl"
                      small
                      type="grow"
                      class="mr-2"
                    ></b-spinner
                    >Überprüfung erneut starten</b-button
                  >
                </b-card-text>
              </div>
              <div v-else>
                <div class="empty-state">
                  <img src="../assets/checked_urls_waiting.svg" />

                  Die Weiterleitungen wurden noch nicht überprüft.
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-container>

      <!-- ### SUCCESS CARD ### -->
      <transition name="fade">
        <b-card
          class="card__forward"
          header="header"
          v-if="
            this.showNext &&
            !controlSuccess &&
            this.projectstore.aktProject.checked &&
            this.projectstore.aktProject.generated &&
            !this.updates
          "
        >
          <template #header>
            <b-card-title class="success" variant="success"
              >Alle URLs wurden erfolgreich geprüft.
            </b-card-title>
            <b-button class="close-btn" @click="closeForward()"
              ><b-icon icon="x"></b-icon
            ></b-button>
          </template>
          <div class="d-flex">
            <b-card-text>
              <p>Das Projekt ist somit abgeschlossen.</p>
              <small
                >Du kannst jederzeit zurückkommen und Weiterleitungen
                anpassen.</small
              >
            </b-card-text>

            <img src="../assets/empty-urls.svg" />
          </div>
        </b-card>
      </transition>

      <!-- ### ERROR CARD ### -->
      <transition name="fade">
        <b-card
          class="card__forward"
          header="header"
          v-if="
            this.showNext &&
            controlSuccess &&
            this.projectstore.aktProject.checked &&
            this.projectstore.aktProject.generated &&
            !this.updates
          "
        >
          <template #header>
            <b-card-title class="danger" variant="success"
              >Oh Nein, es haben sich Fehler ergeben.
            </b-card-title>
            <b-button class="close-btn" @click="closeForward()"
              ><b-icon icon="x"></b-icon
            ></b-button>
          </template>
          <div class="d-flex">
            <b-card-text>
              <small>
                Behebe die Fehler indem du nochmals zurück gehst und die
                fehlerhaften Weiterleitungen anpasst.
              </small>
              <b-button
                variant="primary"
                class="forward-btn back"
                :to="'/project/' + project + '/generieren'"
                ><b-icon icon="code-square"></b-icon>Zurück</b-button
              >
            </b-card-text>

            <img src="../assets/control-bug.svg" />
          </div>
        </b-card>
      </transition>
    </does-exist>
  </div>
</template>

<script>
import { projectStore } from "../store/project.js";
import { useStore } from "../store/index";
import { newUrlStore } from "../store/newUrls";

import moment from "moment";

export default {
  name: "GenerateRed",
  props: ["project"],
  components: {},

  setup() {
    const store = useStore();
    const newUrlstore = newUrlStore();
    const projectstore = projectStore();

    return {
      projectstore,
      store,
      newUrlstore,

      showNext: true,

      loadingControl: false,

      redirects: [],
    };
  },

  computed: {
    redirectedUrls: {
      get() {
        return this.store.redirectedUrls;
      },
      set(value) {
        this.store.redirectedUrls = value;
      },
    },
    alreadyChecked() {
      return this.projectstore.aktProject.checked !== "";
    },
    checkedDate() {
      return moment(this.projectstore.aktProject.checked).format(
        "DD.MM.YYYY, HH:mm [Uhr]"
      );
    },
    updates() {
      var update = false;

      this.store.redirectedUrls.forEach((elem) => {
        if (
          moment(elem.updatedAt).isAfter(this.projectstore.aktProject.checked)
        ) {
          update = true;
        }
      });

      return update;
    },

    controlSuccess() {
      var success = false;
      this.store.redirectedUrls.forEach((elem) => {
        if (elem.checked.code != "301" && elem.checked.code != "200") {
          success = true;
        }
      });

      return success;
    },
  },
  methods: {
    async controlRed() {
      //
      this.loadingControl = true;
      await this.store.checkRed(this.projectstore.aktProject);
      // this.store.redirectedUrls = ["empty"];
      await this.store.fetchUrls(this.project);
      // this.$forceUpdate();
      this.loadingControl = false;
      this.showNext = true;
    },

    closeForward() {
      this.showNext = false;
    },

    removeTrailingSlash(url) {
      // console.log(url);
      if (url !== undefined && url != "") {
        // console.log("removeTrailingSlash", url.replace(/\/?(\?|#|$)/, "$1"));
        return url.replace(/\/?(\?|#|$)/, "$1");
      } else {
        return url;
      }
    },

    changeStagingToLiveDomain(url) {
      // console.log(
      //   "changeURL",
      //   url.replace(
      //     this.projectstore.aktProject.stagingUrl,
      //     this.projectstore.aktProject.newUrl
      //   )
      // );
      return url.replace(
        this.projectstore.aktProject.stagingUrl,
        this.projectstore.aktProject.newUrl
      );
    },
  },

  async created() {
    if (this.project !== this.projectstore.aktProject) {
      this.store.loadingFetchUrls = true;
      await this.projectstore.fetchProjects();
      await this.projectstore.fetchAktConfig(this.project);
      await this.store.fetchUrls(this.project);
      await this.newUrlstore.fetchNewUrlsFirst(
        this.projectstore.aktProject.stagingUrl,
        this.project
      );
    }
  },
};
</script>
