<template>
  <div class="main home pflegen">
    <does-exist>
      <b-container id="app">
        <b-row>
          <b-col
            ><div class="headline-main">
              <h2>
                <b-icon icon="pencil-square"></b-icon
                ><span>Weiterleitungen pflegen</span>
              </h2>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <!-- ### COL 1 ### -->
          <b-col cols="12" lg="6" xl="4">
            <b-card header="header" class="card__url-list v-step-0">
              <template #header>
                <b-card-title class=""
                  >Aktuelle URLs
                  <sup v-if="!loadingOldUrls">{{
                    urlsToRedirect.length
                  }}</sup></b-card-title
                >
                <b-btn
                  class="btn icon-btn bg-btn btn-secondary"
                  id="tooltip-target-add-url"
                  @click="showAddModal()"
                  ><b-icon icon="plus" left></b-icon
                ></b-btn>
                <b-tooltip
                  target="tooltip-target-add-url"
                  triggers="hover"
                  variant="light"
                  placement="top"
                >
                  URL hinzufügen.
                </b-tooltip>
              </template>
              <div v-if="this.loadingOldUrls">
                <b-card-text>
                  <b-alert class="alert__loading" show variant="secondary"
                    ><b-spinner
                      small
                      variant="light"
                      type="grow"
                      class="mr-3"
                      label="Busy"
                    ></b-spinner
                    >URLs werden geladen...</b-alert
                  >
                </b-card-text>
              </div>
              <div v-else>
                <div class="empty-state" v-if="this.urlsToRedirect == ''">
                  <img src="../assets/empty-urls.svg" />
                  <strong>Hooray,</strong>alle URLs wurden erfolgreich
                  zugeordnet.
                </div>
                <b-card-text>
                  <b-list-group>
                    <draggable
                      v-model="urlsToRedirect"
                      v-bind="dragOptions"
                      @start="drag = true"
                      @end="drag = false"
                      :sort="true"
                      :emptyInsertThreshold="50"
                      class="draggable"
                    >
                      <transition-group
                        appear
                        type="transition"
                        :name="!drag ? 'flip-list' : null"
                      >
                        <!-- v-html="defrag(urls.url)" -->
                        <b-list-group-item
                          v-for="urls in urlsToRedirect.slice(0, loadedUrls)"
                          :key="urls._id"
                          class="list-group-item drag"
                        >
                          <div class="url-container">
                            <b-btn
                              class="icon-btn-small"
                              :id="'tooltip-target-open-' + urls._id"
                              :href="urls.url"
                              target="_blank"
                              ><b-icon icon="globe" left></b-icon
                            ></b-btn>
                            <b-tooltip
                              :target="'tooltip-target-open-' + urls._id"
                              triggers="hover"
                              variant="light"
                              placement="top"
                            >
                              Im Browser öffnen
                            </b-tooltip>

                            <span>{{ urls.url }}</span>
                          </div>

                          <!-- <div class="buttons"> -->
                          <b-btn
                            class="icon-btn"
                            :id="'tooltip-target-' + urls._id"
                            @click="showDeakModal(urls)"
                            ><b-icon icon="x" left></b-icon
                          ></b-btn>
                          <b-tooltip
                            :target="'tooltip-target-' + urls._id"
                            triggers="hover"
                            variant="light"
                            placement="right"
                          >
                            URL deaktivieren
                          </b-tooltip>
                          <!-- </div> -->
                        </b-list-group-item>
                      </transition-group>
                    </draggable>
                  </b-list-group>
                </b-card-text>
                <b-card-text
                  class="card__url-list__loaded"
                  v-if="parseInt(urlsToRedirect.length) > parseInt(loadedUrls)"
                >
                  {{ parseInt(loadedUrls) }} /
                  {{ parseInt(urlsToRedirect.length) }} Urls geladen
                </b-card-text>
                <b-button
                  class="load-more-button"
                  variant="secondary"
                  v-on:click="loadedUrls += 300"
                  v-if="parseInt(urlsToRedirect.length) > parseInt(loadedUrls)"
                >
                  Mehr Urls laden
                </b-button>
              </div>
            </b-card>
            <b-card header="header" class="card__disabled v-step-2">
              <template #header>
                <b-card-title class="">Deaktivierte URLs</b-card-title>
              </template>
              <div v-if="this.loadingOldUrls">
                <b-card-text>
                  <b-alert class="alert__loading" show variant="secondary"
                    ><b-spinner
                      small
                      variant="light"
                      type="grow"
                      class="mr-3"
                      label="Busy"
                    ></b-spinner
                    >Deaktivierte URLs werden geladen...</b-alert
                  >
                </b-card-text>
              </div>
              <div v-else>
                <div class="empty-state" v-if="this.urlsDisabled == ''">
                  <img src="../assets/empty-redirects.svg" />

                  Keine deaktivierten URLs vorhanden.
                </div>
                <b-card-text>
                  <b-list-group>
                    <TransitionGroup name="list" mode="out-in">
                      <b-list-group-item
                        class="disabled-item"
                        v-for="urls in urlsDisabled"
                        :key="urls._id"
                      >
                        <div class="item--content">
                          {{ urls.url }}

                          <div class="reason">
                            <b-icon icon="info-circle"></b-icon
                            >{{ urls.disabled.reason }}
                          </div>
                        </div>

                        <!-- <div class="buttons"> -->
                        <b-btn
                          class="icon-btn"
                          :id="'tooltip-target-' + urls._id"
                          ><b-icon
                            @click="enableUrl(urls._id)"
                            icon="plus"
                            left
                          ></b-icon
                        ></b-btn>

                        <b-tooltip
                          :target="'tooltip-target-' + urls._id"
                          triggers="hover"
                          variant="danger"
                          placement="right"
                        >
                          URL aktivieren
                        </b-tooltip>
                        <!-- </div> -->
                      </b-list-group-item>
                    </TransitionGroup>
                  </b-list-group>
                </b-card-text>
              </div>
            </b-card>
          </b-col>

          <!-- ### COL 2 ### -->
          <b-col cols="12" lg="6" xl="3">
            <b-card class="config-form v-step-1" header="header">
              <template #header>
                <b-card-title class="text-dark"
                  >Neue Weiterleitung anlegen</b-card-title
                >
              </template>
              <b-card-text>
                <b-alert
                  v-if="errorstore.getErrorsOnRedirect !== ''"
                  variant="danger"
                  show
                >
                  {{ getErrorsOnRedirect }}
                </b-alert>
                <b-list-group>
                  <div
                    class="drag-label text-dark"
                    v-if="
                      !Object.keys(this.selected).length && this.drag == false
                    "
                  >
                    <b-icon icon="arrows-move"></b-icon><br />
                    URLs per Drag & Drop ablegen
                  </div>

                  <div
                    class="drag-label ondrag text-dark"
                    v-if="
                      !Object.keys(this.selected).length && this.drag == true
                    "
                  >
                    <b-icon icon="arrow-down"></b-icon><br />
                    Hier fallen lassen
                  </div>

                  <draggable
                    v-model="selected"
                    v-bind="dragOptions"
                    @start="drag = true"
                    @end="drag = false"
                    :sort="true"
                    :emptyInsertThreshold="50"
                    class="draggable dashed"
                    :class="{
                      ondrag:
                        !Object.keys(this.selected).length && this.drag == true,
                    }"
                  >
                    <!-- <transition-group
                      type="transition"
                      :name="!drag ? 'flip-list-1' : null"
                    > -->
                    <b-list-group-item
                      v-for="selected in selected"
                      :key="selected._id"
                      class="list-group-item drag"
                    >
                      {{ selected.url }}
                    </b-list-group-item>
                    <!-- </transition-group> -->
                  </draggable>
                </b-list-group>
                <!-- <transition name="fade"> -->
                <div class="text-dark" v-if="Object.keys(this.selected).length">
                  <label>Weiterleiten nach:</label>
                  <b-input-group>
                    <template #prepend>
                      <b-icon icon="arrow-return-right"></b-icon>
                    </template>
                    <b-form-input
                      v-model="redirectTo"
                      autocomplete="off"
                      list="my-list-id"
                      placeholder="URL auswählen"
                    ></b-form-input>

                    <datalist
                      id="my-list-id"
                      v-if="this.loadingNewUrls == false"
                    >
                      <option v-for="item in newUrls" :key="item._id">
                        {{ item.url }}
                      </option>
                    </datalist>

                    <datalist
                      id="my-list-id"
                      v-if="this.loadingNewUrls == true"
                    >
                      <option>URLs werden geladen...</option>
                    </datalist>
                  </b-input-group>
                  <div class="setredirect-buttons">
                    <b-button
                      class=""
                      variant="secondary"
                      v-on:click="abortRedirect()"
                    >
                      Abbrechen</b-button
                    >
                    <b-button
                      variant="primary"
                      class="send"
                      v-on:click="setRedirect()"
                    >
                      <b-icon icon="arrow-right" left></b-icon
                      >Weiterleiten</b-button
                    >
                  </div>
                </div>
                <!-- </transition> -->
              </b-card-text>
            </b-card>
          </b-col>

          <!-- ### COL 3 ### -->
          <b-col cols="12" md="12" xl="4" offset="0" offset-xl="1">
            <b-card class="card__redirects v-step-3" header="header">
              <template #header>
                <b-card-title class="text-dark"
                  >Angelegte Weiterleitungen
                  <sup v-if="!loadingOldUrls">{{
                    redirectedUrls.length
                  }}</sup></b-card-title
                >
              </template>
              <div v-if="this.loadingOldUrls">
                <b-card-text>
                  <b-alert class="alert__loading" show variant="secondary"
                    ><b-spinner
                      small
                      variant="light"
                      type="grow"
                      class="mr-3"
                      label="Busy"
                    ></b-spinner
                    >Weiterleitungen werden geladen...</b-alert
                  >
                </b-card-text>
              </div>
              <div v-else>
                <b-card-text text v-if="this.redirectedUrls == ''">
                  <div class="empty-state">
                    <img src="../assets/empty-redirects.svg" />

                    Es sind keine Weiterleitungen angelegt.
                  </div>
                </b-card-text>
                <b-card-text>
                  <b-list-group>
                    <TransitionGroup name="list" mode="out-in">
                      <b-list-group-item
                        v-for="urls1 in redirectedUrls.slice().reverse()"
                        :key="urls1._id"
                        class="redirect-item"
                      >
                        <div class="url-text">
                          <span class="old-url">{{ urls1.url }}</span
                          ><br />
                          <span class="newurl-item">
                            <b-icon icon="arrow-return-right"></b-icon>
                            <span class="new-url">{{ urls1.newUrl }}</span>
                          </span>
                        </div>
                        <div class="buttons">
                          <b-button
                            class="icon-btn"
                            :id="'tooltip-delete-' + urls1._id"
                            @click="showDelRedirectModal(urls1)"
                            ><b-icon icon="trash-fill"></b-icon>
                          </b-button>

                          <b-tooltip
                            :target="'tooltip-delete-' + urls1._id"
                            triggers="hover"
                            variant="danger"
                            placement="top"
                          >
                            Weiterleitung löschen
                          </b-tooltip>

                          <b-button
                            class="icon-btn bg-btn"
                            @click="showEditModal(urls1)"
                            :id="'tooltip-edit-' + urls1._id"
                            ><b-icon icon="pencil-fill"></b-icon
                          ></b-button>

                          <b-tooltip
                            :target="'tooltip-edit-' + urls1._id"
                            triggers="hover"
                            variant="light"
                            placement="top"
                          >
                            Weiterleitung bearbeiten
                          </b-tooltip>
                        </div>
                      </b-list-group-item>
                    </TransitionGroup>
                  </b-list-group>
                </b-card-text>
              </div>
            </b-card>

            <!-- ### EDIT MODAL ### -->
            <b-modal hide-footer ref="edit-modal" modal-class="modal__default">
              <template #modal-title>
                <b-icon icon="pencil-fill"></b-icon>Weiterleitung bearbeiten
              </template>
              <b-list-group class="modal__edit"
                ><b-list-group-item>
                  {{ onEdit.url }}
                </b-list-group-item></b-list-group
              >
              <label> Weiterleiten nach:</label>

              <b-form-input
                v-model="onEditRedirectTo"
                autocomplete="off"
                list="my-list-id"
                placeholder="Url auswählen"
              ></b-form-input>

              <datalist id="my-list-id" v-if="this.loadingNewUrls == false">
                <option v-for="item in newUrls" :key="item._id">
                  {{ item.url }}
                </option>
              </datalist>

              <datalist id="my-list-id" v-if="this.loadingNewUrls == true">
                <option>Urls werden geladen...</option>
              </datalist>

              <div class="modal-buttons">
                <b-button variant="secondary" v-on:click="closeRedirect()">
                  Abbrechen</b-button
                >
                <b-button variant="primary" v-on:click="editRedirect()">
                  <b-icon icon="pencil-fill"></b-icon>Speichern</b-button
                >
              </div>
            </b-modal>

            <!-- ### DELETE MODAL ### -->
            <b-modal
              hide-footer
              class="text-dark"
              ref="delete-modal"
              modal-class="modal__default"
            >
              <template #modal-title>
                <b-icon icon="trash-fill"></b-icon>Weiterleitung löschen
              </template>
              <b-list-group class="model__delete"
                ><b-list-group-item class="redirect-item">
                  <span class="old-url">{{ onDelete.url }}</span
                  ><br />
                  <span class="newurl-item">
                    <b-icon icon="arrow-return-right"></b-icon>
                    <span class="new-url">{{ onDelete.newUrl }}</span>
                  </span>
                </b-list-group-item></b-list-group
              >
              <div class="modal-buttons">
                <b-button
                  class=""
                  variant="secondary"
                  v-on:click="closeDelRedirect()"
                >
                  Abbrechen</b-button
                >

                <b-btn
                  class="delete"
                  variant="primary"
                  v-on:click="deleteRedirect()"
                >
                  <b-icon icon="trash-fill"></b-icon>Löschen</b-btn
                >
              </div>
            </b-modal>

            <!-- ### DEAKTIVIEREN MODAL ### -->
            <b-modal hide-footer ref="deak-modal" modal-class="modal__default">
              <template #modal-title>
                <b-icon icon="x-lg"></b-icon>Url deaktivieren
              </template>
              <b-list-group class="modal__deaktivieren"
                ><b-list-group-item>
                  {{ onDeak.url }}
                </b-list-group-item></b-list-group
              >
              <label class="text-dark" for="form-input-username"
                >Grund auswählen</label
              >
              <b-form-input
                v-model="onDeakReason"
                autocomplete="off"
                list="my-list-deak"
                placeholder="Grund auswählen"
              ></b-form-input>
              <datalist id="my-list-deak">
                <option>URL gibt es auch auf der neuen Seite.</option>
                <option>URL ist doppelt vorhanden.</option>
                <option>URL ist fehlerhaft.</option>
              </datalist>

              <div class="modal-buttons">
                <b-button class="" variant="secondary" v-on:click="closeDeak()">
                  Abbrechen</b-button
                >

                <b-btn class="" variant="primary" v-on:click="onDeakUrl()">
                  <b-icon icon="x-lg"></b-icon>Deaktivieren</b-btn
                >
              </div>
            </b-modal>

            <!-- ### Add URL MODAL ### -->
            <b-modal hide-footer ref="add-modal" modal-class="modal__default">
              <template #modal-title>
                <b-icon icon="plus-lg"></b-icon>Url hinzufügen
              </template>

              <p>Bitte URL eingeben, welche hinzugefügt werden soll.</p>

              <b-alert
                v-if="errorstore.getErrorOnAddUrl !== ''"
                variant="danger"
                show
              >
                {{ getErrorOnAddUrl }}
              </b-alert>

              <b-form-input
                v-model="onAddInput"
                autocomplete="off"
                placeholder="URL eingeben"
              ></b-form-input>

              <div class="modal-buttons">
                <b-button class="" variant="secondary" v-on:click="closeAdd()">
                  Abbrechen</b-button
                >

                <b-btn class="" variant="primary" v-on:click="onAddUrl()">
                  <b-icon icon="plus-lg"></b-icon>Hinzufügen</b-btn
                >
              </div>
            </b-modal>
          </b-col>
        </b-row>
      </b-container>

      <transition name="fade">
        <b-card
          class="card__forward"
          header="header"
          v-if="
            this.showNext &&
            this.urlsToRedirect.length == 0 &&
            this.selected.length == 0 &&
            this.urls.length > 0
          "
        >
          <template #header>
            <b-card-title variant="success"
              >Alle Weiterleitungen wurden erfolgreich gepflegt.
            </b-card-title>
            <b-button class="close-btn" @click="closeForward()"
              ><b-icon icon="x"></b-icon
            ></b-button>
          </template>

          <div v-if="$route.name != 'customer-home'">
            <b-card-text>
              Lasse nun im nächsten Schritt die Redirects von einem Entwickler
              generieren.
            </b-card-text>
            <b-button variant="primary" class="forward-btn" @click="forward()"
              ><b-icon icon="code-square"></b-icon>Zum nächsten
              Schritt</b-button
            >
          </div>
          <div v-if="$route.name == 'customer-home'">
            <b-card-text>
              Deine Arbeit ist an dieser Stelle abgeschlossen.
            </b-card-text>
          </div>
        </b-card>
      </transition>

      <v-tour name="myTour" :steps="steps" :options="tourOptions">
        <template slot-scope="tour">
          <transition name="fade">
            <v-step
              v-if="tour.steps[tour.currentStep]"
              :key="tour.currentStep"
              :step="tour.steps[tour.currentStep]"
              :previous-step="tour.previousStep"
              :next-step="tour.nextStep"
              :stop="tour.stop"
              :skip="tour.skip"
              :is-first="tour.isFirst"
              :is-last="tour.isLast"
              :labels="tour.labels"
            >
              <template v-if="tour.currentStep === 0">
                <div slot="content">
                  <b-progress value="1" max="5"></b-progress>
                  <p>
                    Greife eine oder mehrere URLs und ziehe sie nach rechts in
                    das Drag & Drop Feld.
                  </p>
                  <img src="../assets/tour-pflegen-22.png" /> <br />
                </div>
              </template>
              <template v-if="tour.currentStep === 1">
                <div slot="content">
                  <b-progress value="2" max="5"></b-progress>
                  <p>
                    Leite die ausgewählten URLs nun auf eine der Staging URLs
                    weiter.
                  </p>
                  <img src="../assets/tour-pflegen-21.png" /> <br />
                </div>
              </template>
              <template v-if="tour.currentStep === 2">
                <div slot="content">
                  <b-progress value="3" max="5"></b-progress>
                  <p>
                    URLs welche du nicht weiterleiten möchtest, kannst du über
                    das "X" zu den deaktivierten URLs hinzufügen.
                  </p>
                  <img src="../assets/tour-pflegen-23.png" /> <br />
                </div>
              </template>

              <template v-if="tour.currentStep === 3">
                <div slot="content">
                  <b-progress value="4" max="5"></b-progress>
                  <p>
                    Hier findest du eine Auflistung aller angelegten
                    Weiterleitungen.
                  </p>
                  <img src="../assets/tour-pflegen-24.png" /> <br />
                </div>
              </template>

              <template v-if="tour.currentStep === 4">
                <div slot="content">
                  <b-progress value="5" max="5"></b-progress>
                  <div class="content">
                    <img src="../assets/tour-pflegen-end.png" />
                    <div class="text">
                      <p class="small">Das Tutorial ist abgeschlossen.</p>
                      <p>Lege nun los und pflege die Weiterleitungen.</p>
                    </div>
                  </div>
                </div>
              </template>
            </v-step>
          </transition>
        </template>
      </v-tour>

      <div
        id="popover-tutorial-start"
        class="tutorial-start"
        @click="startTutorial()"
      >
        <b-icon icon="book"></b-icon>Tutorial <br />starten
      </div>
      <!-- <transition name="fade-long">
        <b-popover
          :show.sync="showTutorialPopover"
          target="popover-tutorial-start"
          custom-class="tutorial-popover"
          placement="righttop"
          ref="tutorialPopover"
        >
          <p>Solltest du Hilfe benötigen, <br />findest du diese hier.</p>
          <b-button variant="primary" class="" @click="closeTutPopover()"
            >Verstanden</b-button
          >
        </b-popover>
      </transition> -->
    </does-exist>
  </div>
</template>

<script>
import { useStore } from "../store/index.js";
import { projectStore } from "../store/project.js";
import { newUrlStore } from "../store/newUrls.js";
import { errorStore } from "../store/error.js";
import draggable from "vuedraggable-multi";
// import { reactive, ref } from "@vue/composition-api";
import router from "../router";
import ConfigService from "@/services/ConfigService.js";

export default {
  props: ["project"],

  components: {
    draggable,
  },
  setup() {
    const store = useStore();
    const newUrlstore = newUrlStore();
    const projectstore = projectStore();
    const errorstore = errorStore();

    // const items = reactive(newUrlstore.newUrls);

    return {
      before: null,
      editing: true,
      drag: false,

      selected: [],

      store,
      newUrlstore,
      projectstore,
      errorstore,

      router,

      file: [],
      upload: [],

      redirectTo: "",

      onEditRedirectTo: "",
      onEdit: "",

      onDelete: "",

      onDeak: "",
      onDeakReason: "",

      onAddInput: "",

      show: false,
      message: "",
      marker: true,

      showNext: true,

      showTutorialPopover: false,

      initValue: 0,

      loadedUrls: 300,

      tourOptions: {
        debug: true,
        labels: {
          buttonSkip: "Tutorial Überspringen",
          buttonPrevious: "Letzter Tipp",
          buttonNext: "Nächster Tipp",
          buttonStop: "Tutorial abschließen",
        },
      },

      steps: [
        {
          target: ".v-step-0",
          content: " ",
          offset: -200,
          params: {
            placement: "right-start",
            enableScrolling: true,
            highlight: true,
          },
        },
        {
          target: ".v-step-1",
          content: " ",
          offset: -200,
          params: {
            enableScrolling: true,
            placement: "top",
            highlight: true,
          },
        },
        {
          target: ".v-step-2",
          content: " ",
          offset: -500,
          params: {
            placement: "bottom",
            enableScrolling: true,
            highlight: true,
          },
        },
        {
          target: ".v-step-3",
          sticky: false,
          content: " ",
          offset: -200,
          params: {
            placement: "bottom",
            enableScrolling: true,
            highlight: true,
          },
        },
        {
          content: " ",
          offset: -200,
          params: {
            enableScrolling: true,
            highlight: true,
          },
        },
      ],
    };
  },

  computed: {
    options() {
      return {
        disabled: !this.editing,
      };
    },
    urls: {
      get() {
        return this.store.urls;
      },
      set(value) {
        this.store.urls = value;
      },
    },
    newUrls() {
      return this.newUrlstore.newUrls;
    },
    urlsToRedirect: {
      get() {
        return this.store.urlsToRedirect;
      },
      set(value) {
        this.store.urlsToRedirect = value;
      },
    },
    urlsToRedirectShort: {
      get() {
        return this.store.urlsToRedirect.slice(0, this.loadedUrls);
      },
      set(value) {
        this.store.urlsToRedirect = value;
      },
    },
    urlsDisabled: {
      get() {
        return this.store.urlsDisabled;
      },
      set(value) {
        this.store.urlsDisabled = value;
      },
    },
    redirectedUrls: {
      get() {
        return this.store.redirectedUrls;
      },
      set(value) {
        this.store.redirectedUrls = value;
      },
    },
    getErrorsOnRedirect() {
      return this.errorstore.getErrorsOnRedirect;
    },
    getErrorOnAddUrl() {
      return this.errorstore.getErrorOnAddUrl;
    },
    loadingOldUrls() {
      return this.store.loadingFetchUrls;
    },
    loadingNewUrls() {
      return this.newUrlstore.loadingNewUrls;
    },
    StoreExists() {
      return this.store.exists;
    },
    dragOptions() {
      return {
        animation: 200,
        group: "items",
        disabled: false,
        ghostClass: "ghost",
        dragClass: "dragging",
        sortable: true,
        multiDrag: true, // Enable the plugin
        selectedClass: "selected", // Class name for selected item
        // multiDragKey: "CTRL", // Key that must be down for items to be selected
        avoidImplicitDeselect: true, // true - if you don't want to deselect items on outside click
        scrollSensitivity: 200,
        // forceFallback: true,

        // Called when an item is selected
        onSelect: function (/**Event*/ evt) {
          evt.item;
        },

        // Called when an item is deselected
        onDeselect: function (/**Event*/ evt) {
          evt.item;
        },
      };
    },
  },
  methods: {
    action(e) {
      if (e === "edit") this.before = Object.assign([], this.items);
      if (e === "undo") this.items = this.before;
      this.editing = !this.editing;
      // this.setUrls(this.urls);
    },
    setUrls(urls) {
      this.store.setUrls(urls);
    },

    // *** - SET NEW REDIRECT - *** //
    setRedirect() {
      // clear errorstore
      this.errorstore.errorOnRedirect = "";

      // loop through all selected urls
      this.selected.forEach((elem) => {
        // check if url to redirect is selected
        if (this.redirectTo == "") {
          this.errorstore.errorOnRedirect = "Bitte eine URL auswählen.";
        } else {
          // set element new url
          elem.newUrl = this.redirectTo;
          elem.updatedAt = new Date();

          // check akturl = newurl
          if (
            this.oldisnew(elem.url).replace(
              this.oldisnew(this.projectstore.aktProject.aktUrl, "")
            ) ===
            this.oldisnew(elem.newUrl).replace(
              this.oldisnew(this.projectstore.aktProject.stagingUrl, "")
            )
          ) {
            if (
              this.projectstore.aktProject.aktUrl ===
              this.projectstore.aktProject.newUrl
            ) {
              this.errorstore.errorOnRedirect =
                "Die URL leitet auf die selbe URL. Bitte eine andere URL auswählen oder URL deaktivieren.";
            }
          }
          //check if newurl contains staging-domain
          else if (
            !elem.newUrl.includes(
              this.oldisnew(this.projectstore.aktProject.stagingUrl)
            )
          ) {
            this.errorstore.errorOnRedirect =
              "URL leitet nicht auf eine URL der Domain " +
              this.projectstore.aktProject.stagingUrl +
              " !";
          }
        }
      });

      // if no error clear all vars
      if (this.errorstore.errorOnRedirect === "") {
        this.selected.forEach((elem) => {
          // loop through main urls list
          this.urls.forEach((item) => {
            // select selected urls in main url list
            if (item._id == elem._id) {
              // update main url list
              item.newUrl = elem.newUrl;
              this.store.updateDB(this.project, item._id);
            }
          });

          // push this element to redirectedUrls list
          this.redirectedUrls.push(elem);

          this.makeToast(
            this.projectstore.aktProject.proj_id,
            "Die Weiterleitung wurde erfolgreich angelegt."
          );
        });

        this.redirectTo = "";
        this.selected = [];
      }

      this.projectstore.aktProject.open = this.store.urlsToRedirect.length;
      this.store.updateConfigDB(this.projectstore.aktProject);
    },
    abortRedirect() {
      this.selected.forEach((elem) => {
        this.urlsToRedirect.push(elem);
      });
      this.selected = [];
      this.redirectTo = "";
      this.errorstore.errorOnRedirect = "";
    },

    oldisnew(url) {
      let urlString = url;
      if (urlString.substring(urlString.length - 1) == "/") {
        urlString = urlString.substring(0, urlString.length - 1);
      }
      if (urlString.includes("https://")) {
        urlString = urlString.replace("https://", "");
      }
      if (urlString.includes("http://")) {
        urlString = urlString.replace("http://", "");
      }
      if (urlString.includes("www.")) {
        urlString = urlString.replace("www.", "");
      }

      return urlString;
    },

    showAddModal() {
      this.$refs["add-modal"].show();
    },

    closeAdd() {
      this.onAddInput = "";
      this.$refs["add-modal"].hide();
    },

    onAddUrl() {
      this.errorstore.errorOnAddUrl = "";

      if (this.onAddInput != "") {
        this.urls.forEach((url) => {
          if (url.url === this.onAddInput) {
            this.errorstore.errorOnAddUrl =
              "URL ist schon vorhanden. Bitte andere URL angeben.";

            // this.removeById(this.urlsToRedirect, url._id);
            // this.onDeak = "";
            // this.onDeakReason = "";
            // this.urlsDisabled.push(url);
            // this.store.updateDB(this.project, url._id);
          }
        });
      } else {
        this.errorstore.errorOnAddUrl = "Bitte gültige URL angeben.";

        this.makeToast(
          this.projectstore.aktProject.proj_id,
          "Die URL konnte nicht hinzugefügt werden. Bitte URL angeben."
        );
      }

      if (this.errorstore.errorOnAddUrl === "") {
        this.$refs["add-modal"].hide();
        this.store.addUrl(
          this.projectstore.aktProject.proj_id,
          this.onAddInput
        );

        this.onAddInput = "";

        this.makeToast(
          this.projectstore.aktProject.proj_id,
          "Die URL wurde erfolgreich hinzugefügt."
        );
      }
    },

    showDeakModal(url) {
      this.onDeak = url;
      this.$refs["deak-modal"].show();
    },

    closeDeak() {
      this.onDeakReason = "";
      this.onDeakUrl = "";
      this.$refs["deak-modal"].hide();
    },

    onDeakUrl() {
      this.urls.forEach((url) => {
        if (url._id === this.onDeak._id) {
          url.disabled = {
            state: true,
            reason: this.onDeakReason,
          };
          this.removeById(this.urlsToRedirect, url._id);
          this.onDeak = "";
          this.onDeakReason = "";
          this.urlsDisabled.push(url);
          this.store.updateDB(this.project, url._id);
        }
      });
      this.$refs["deak-modal"].hide();

      this.projectstore.aktProject.open = this.store.urlsToRedirect.length;
      this.store.updateConfigDB(this.projectstore.aktProject);

      this.makeToast(
        this.projectstore.aktProject.proj_id,
        "Die URL wurde erfolgreich deaktiviert."
      );

      // this.store.urls = this.urls;
    },
    removeById(arr, id) {
      const requiredIndex = arr.findIndex((el) => {
        return el._id === String(id);
      });
      if (requiredIndex === -1) {
        return false;
      }
      return !!arr.splice(requiredIndex, 1);
    },

    showEditModal(url) {
      this.onEdit = url;
      this.onEditRedirectTo = url.newUrl;
      this.$refs["edit-modal"].show();
    },

    enableUrl(id) {
      this.urlsDisabled.forEach((url) => {
        if (url._id === id) {
          url.disabled = {
            state: false,
            by: -1,
            reason: "-1",
          };
          this.removeById(this.urlsDisabled, url._id);
          this.urlsToRedirect.push(url);
          this.store.updateDB(this.project, url._id);
        }
      });
      this.makeToast(
        this.projectstore.aktProject.proj_id,
        "Die URL wurde erfolgreich aktiviert."
      );

      // this.store.urls = this.urls;
    },

    editRedirect() {
      // loop through all selected urls
      this.urls.forEach((elem) => {
        if (this.onEdit._id === elem._id) {
          // set element new url
          elem.newUrl = this.onEditRedirectTo;
          elem.checked = [];
          elem.updatedAt = new Date();

          // loop through main urls list
          this.redirectedUrls.forEach((item) => {
            // select selected urls in main url list
            if (item._id === elem._id) {
              // update main url list
              item.newUrl = elem.newUrl;
              this.store.updateDB(this.project, elem._id);
            }
          });
        }
      });
      this.$refs["edit-modal"].hide();
      this.onEditRedirectTo = "";
      this.makeToast(
        this.projectstore.aktProject.proj_id,
        "Die Weiterleitung wurde erfolgreich geändert."
      );
    },

    closeRedirect() {
      this.$refs["edit-modal"].hide();
    },

    showDelRedirectModal(url) {
      this.onDelete = url;
      this.$refs["delete-modal"].show();
    },

    closeDelRedirect() {
      this.$refs["delete-modal"].hide();
    },

    deleteRedirect() {
      // loop through all selected urls
      this.urls.forEach((elem) => {
        if (this.onDelete._id === elem._id) {
          // set element new url
          elem.newUrl = "";
          elem.checked = [];
          elem.updatedAt = new Date();

          let i = 0;
          // loop through main urls list
          this.redirectedUrls.forEach((item) => {
            // select selected urls in main url list
            if (item._id === elem._id) {
              // update main url list

              this.redirectedUrls.splice(i, 1);

              this.store.updateDB(this.project, elem._id);
            }
            i++;
          });
          this.urlsToRedirect.push(elem);
        }
      });
      this.$refs["delete-modal"].hide();
      this.makeToast(
        this.projectstore.aktProject.proj_id,
        "Die Weiterleitung wurde erfolgreich gelöscht."
      );

      this.projectstore.aktProject.open = this.store.urlsToRedirect.length;
      this.store.updateConfigDB(this.projectstore.aktProject);
    },

    startTutorial() {
      this.$tours["myTour"].start();
      this.showTutorialPopover = false;
      this.$refs.tutorialPopover.$emit("disable");
    },

    closeForward() {
      this.showNext = false;
    },
    forward() {
      this.showNext = true;
      this.router.push(
        "/project/" + this.projectstore.aktProject.proj_id + "/generieren"
      );
    },

    closeTutPopover() {
      this.showTutorialPopover = false;
      this.$refs.tutorialPopover.$emit("disable");
    },
  },

  async created() {
    if (this.project !== this.projectstore.aktProject.proj_id) {
      this.store.loadingFetchUrls = true;
      await this.projectstore.fetchProjects();
      await this.projectstore.fetchAktConfig(this.project);
      await this.store.fetchUrls(this.project);
      await this.newUrlstore.fetchNewUrlsFirst(
        this.projectstore.aktProject.stagingUrl,
        this.project
      );
      this.showTutorialPopover = true;
    }
  },
};
</script>
