<template>
  <div class="home main errorpage">
    <error-state></error-state>
  </div>
</template>

<script>
export default {
  name: "ErrPage404",

  components: {},

  setup() {
    return {};
  },

  computed: {},
  methods: {},

  async created() {
    //
  },
};
</script>
