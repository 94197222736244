<template>
  <div class="home home--customer">
    <div>
      <transition name="nav-fade">
        <div class="header">
          <!-- *** HEADERBAR *** -->
          <div class="headerbar">
            <div class="headerbar__logo">
              <img src="../assets/teufels-logo.svg" />
            </div>
            <div class="headerbar__headline" v-if="!this.isProject">
              {{ aktproject }}
            </div>
            <div class="headerbar__meta">
              <p class="info__offline" v-if="this.isOffline">
                <b-icon icon="exclamation-triangle"></b-icon> Offline
              </p>
              <div class="headerbar__infobutton">
                <transition name="fade" v-if="!this.isProject">
                  <span v-if="!this.isProject">
                    <b-dropdown
                      size="lg"
                      right
                      variant="outline"
                      class="dropdown-header info"
                      toggle-class="text-decoration-none"
                    >
                      <template #button-content>
                        <b-icon icon="info-circle"></b-icon>
                      </template>
                      <transition name="fade">
                        <b-dropdown-text class="dropdown__text">
                          <b-dropdown-header
                            class="dropdown_metadata"
                            id="dropdown-label"
                          >
                            <table>
                              <tr>
                                <td class="info_headline">Projekt-ID:</td>
                                <td>{{ this.aktproject }}</td>
                              </tr>
                              <tr>
                                <td class="info_headline">Aktuelle Domain:</td>
                                <td>{{ this.aktUrl }}</td>
                              </tr>
                              <tr>
                                <td class="info_headline">
                                  Spätere Live-Domain:
                                </td>
                                <td>{{ this.newUrl }}</td>
                              </tr>
                              <tr>
                                <td class="info_headline">Staging-Domain:</td>
                                <td>{{ this.stagingUrl }}</td>
                              </tr>
                            </table>
                          </b-dropdown-header>

                          <div class="buttons">
                            <b-dropdown-group header="URLs">
                              <!-- <b-button
                                variant="secondary"
                                @click="openFetchAktDomain()"
                                ><b-icon icon="binoculars-fill"></b-icon
                                >Aktuelle Domain erneut crawlen</b-button
                              > -->
                              <b-button
                                variant="secondary"
                                @click="onFetchNew()"
                                ><b-spinner
                                  v-if="loadingFetchUrls"
                                  small
                                  variant="primary"
                                  type="grow"
                                  class="mr-3"
                                  label="Busy"
                                ></b-spinner
                                ><b-icon
                                  v-if="!loadingFetchUrls"
                                  icon="arrow-repeat"
                                ></b-icon
                                >Staging URLs erneuern</b-button
                              >
                            </b-dropdown-group>
                          </div>
                        </b-dropdown-text>
                      </transition>
                    </b-dropdown>
                  </span>
                </transition>
              </div>
            </div>
          </div>

          <!-- *** NAVIGATIONBAR *** -->

          <!-- <div class="nav-bar" v-if="!this.isProject">
            <nav>
              <p>Weiterleitungen</p>

              <span class="line-small"></span>

              <b-button
                class="nav-button"
                :to="'/kunde/' + this.aktproject + '/pflegen'"
              >
                <b-icon icon="pencil-square"></b-icon>
                <span>01 pflegen</span>
              </b-button>

              <span class="line-big"></span>

              <b-button
                :class="{
                  'router-link-active': this.$route.name == 'generate',
                }"
                class="nav-button"
                v-on:click="toGenerate()"
              >
                <b-icon icon="code-square"></b-icon>
                <span>02 generieren</span>
              </b-button>

              <span class="line-big"></span>

              <b-button
                class="nav-button"
                id="btn-control"
                :class="{
                  shake: shakeAnim,
                  'router-link-active': this.$route.name == 'control',
                }"
                v-on:click="onClickControl()"
                v-on:mouseover="onMouseOverControl()"
                v-on:mouseleave="onMouseLeaveControl()"
              >
                <b-icon icon="check2-square"></b-icon>
                <span>03 überprüfen</span>
              </b-button>

              <b-popover
                placement="bottom"
                variant="light"
                :show.sync="show"
                :disabled.sync="disabled"
                target="btn-control"
                custom-class="controlTooltip"
              >
                <p class="danger">Generiere zuerst die Weiterleitungen.</p>
              </b-popover>
            </nav>
          </div> -->

          <b-modal hide-footer ref="generate-modal">
            <p>Du hast noch nicht alle Weiterleitungen gepflegt.</p>

            <p class="danger">Möchtest du wirklich weiter?</p>

            <div class="modal-buttons">
              <b-button variant="secondary" v-on:click="closeGenerateModal()">
                Abbrechen</b-button
              >
              <b-button variant="primary" v-on:click="goToGenerate()">
                <b-icon icon="code-square" class="rotate"></b-icon
                >Weiter</b-button
              >
            </div>
          </b-modal>
        </div>
      </transition>
    </div>
    <div>
      <transition :name="transitionName">
        <slot></slot>
      </transition>
    </div>

    <!-- ### EDIT PROJECTDATA MODAL ### -->
    <b-modal
      hide-footer
      show
      modal-class="modal__editprojectdata"
      ref="editprojectdata-modal"
    >
      <template #modal-title>
        <b-icon class="small" icon="pencil-fill"></b-icon>Projektdaten
        bearbeiten
      </template>

      <b-alert
        v-if="errorstore.getEditProjectError !== ''"
        variant="danger"
        show
        v-html="this.errorstore.getEditProjectError"
      >
      </b-alert>

      <!-- Form for new Project -->
      <!-- FIXME: FORM VALIDATION -->
      <form>
        <!-- ID - INPUT -->
        <b-form-group
          class="text-dark"
          id="fieldset-1"
          label="Project ID*"
          label-for="input-1"
          ><b-input-group>
            <b-form-input
              disabled
              id="input-1"
              v-model="proj_id"
              placeholder="teu_22_ws"
              class="disabled"
              trim
            ></b-form-input>
          </b-input-group>
        </b-form-group>

        <!-- AKT DOMAIN - INPUT -->
        <b-form-group
          class="text-dark"
          id="fieldset-1"
          label="Aktuelle Domain*"
          label-for="input-2"
          :state="this.aktUrlState"
        >
          <b-input-group>
            <template #prepend>
              <b-form-select
                disabled
                v-model="selected"
                :options="options"
              ></b-form-select>
            </template>

            <b-form-input
              disabled
              v-model="editaktUrl"
              class="disabled"
              id="input-2"
              placeholder="teufels.com"
            ></b-form-input>
          </b-input-group>
        </b-form-group>

        <!-- LIVE DOMAIN - INPUT -->

        <b-form-group>
          <label class="text-dark" for="form-input-username"
            >Spätere Live-Domain</label
          >
          <b-input-group prepend="https://">
            <b-form-input
              :state="this.newUrlState"
              v-model="editnewUrl"
              id="form-input-username"
              placeholder="teufels.com"
              :disabled="loadingEditProject"
            ></b-form-input>
          </b-input-group>
        </b-form-group>

        <div class="changeUrl" v-if="newUrlChanges">
          <b-icon icon="exclamation-circle"></b-icon>
          <p>
            Deine bisherigen angelegten Weiterleitungen werden bei dieser
            Änderung der Live-Domain entsprechend angepasst.
          </p>
        </div>

        <!-- STAGING DOMAIN - INPUT -->
        <b-form-group
          ><label class="text-dark" for="form-input-username"
            >Staging Domain*</label
          >
          <b-input-group prepend="https://">
            <b-form-input
              :state="this.stagingUrlState"
              v-model="editstagingUrl"
              id="form-input-username"
              placeholder="staging.teu.teufels-werk-2.de"
              :disabled="loadingEditProject"
            ></b-form-input>
          </b-input-group>
        </b-form-group>

        <div class="modal-buttons">
          <b-button @click="abortEditProject()" class="" variant="secondary">
            Abbrechen</b-button
          >

          <b-btn @click="onSendForm()" variant="primary"
            ><b-spinner
              v-if="loadingEditProject"
              small
              variant="primary"
              type="grow"
              class="mr-3"
              label="Busy"
            ></b-spinner>
            <b-icon v-if="!loadingEditProject" icon="pencil-fill"></b-icon
            >Projektdaten bearbeiten</b-btn
          >
        </div>
      </form>
    </b-modal>

    <!-- DELETE PROJECT MODAL -->
    <b-modal
      hide-footer
      ref="deleteproj-modal"
      modal-class="modal__project-delete"
    >
      <template #modal-title>
        <b-icon icon="trash-fill"></b-icon>Projekt löschen
      </template>

      <p>
        Möchtest du das Projekt
        <b> {{ this.projectstore.aktProject.proj_id }} </b> wirklich löschen?
      </p>

      <div class="modal-buttons">
        <b-button class="" variant="secondary" v-on:click="closeDelProject()">
          Abbrechen</b-button
        >
        <b-btn class="delete" variant="primary" v-on:click="onDeleteProject()">
          <b-icon icon="trash-fill"></b-icon>Projekt löschen</b-btn
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import router from "../router";
import { projectStore } from "../store/project.js";
import { useStore } from "../store/index.js";
import { errorStore } from "../store/error.js";
import { newUrlStore } from "../store/newUrls.js";
import ProjectService from "@/services/ProjectService";
import ConfigService from "@/services/ConfigService";
import update from "./../mixins/update";

export default {
  name: "HomeMain",
  mixins: [update],
  setup() {
    const projectstore = projectStore();
    const store = useStore();
    const errorstore = errorStore();
    const newUrlstore = newUrlStore();

    return {
      projectstore,
      store,
      errorstore,
      newUrlstore,

      form: {},

      editaktUrl: "",
      editnewUrl: "",
      editstagingUrl: "",

      proj_id: "",

      selected: null,
      options: [
        { value: null, text: "https://" },
        { value: "a", text: "http://" },
      ],

      shakeAnim: false,

      router,

      transitionName: "slide-right-100",

      show: false,
      disabled: false,
    };
  },
  watch: {
    ["$route"](to, from) {
      if (
        (from.name == "home" &&
          (to.name == "generate" || to.name == "control")) ||
        (from.name == "generate" && to.name == "control") ||
        (from.name == "generate" && to.name == "control")
      ) {
        this.transitionName = "slide-left";
      } else if (
        (from.name == "control" &&
          (to.name == "generate" || to.name == "home")) ||
        (from.name == "control" && to.name == "home") ||
        (from.name == "generate" && to.name == "home")
      ) {
        this.transitionName = "slide-right";
      } else if (to.name == "all") {
        this.transitionName = "slide-right";
      } else if (from.name == "all") {
        this.transitionName = "slide-left";
      } else {
        this.transitionName == "fade";
      }
    },
  },
  computed: {
    aktproject() {
      return this.projectstore.aktProject.proj_id;
    },
    aktUrl() {
      return this.projectstore.aktProject.aktUrl;
    },
    newUrl() {
      return this.projectstore.aktProject.newUrl;
    },
    stagingUrl() {
      return this.projectstore.aktProject.stagingUrl;
    },
    isProject() {
      return (
        this.$route.name === "all" ||
        this.$route.name === "AllProjects" ||
        this.$route.name === "NewProject" ||
        this.$route.name === "ErrPage404"
      );
    },
    isMain() {
      return this.$route.name === "all" || this.$route.name === "AllProjects";
    },
    isNew() {
      return this.$route.name !== "all";
    },

    isGenerate() {
      return (
        this.$route.name === "generate" || this.$route.name === "AllProjects"
      );
    },
    isGenerateOrControl() {
      return this.$route.name === "generate" || this.$route.name === "control";
    },

    aktUrlState() {
      var re = new RegExp(
        "^[0-9a-zA-Z]([-.w]*[0-9a-zA-Z])*(:(0-9)*)*(/?)([a-zA-Z0-9-.?,'/+&amp;%$#_]*)?$"
      );
      return re.test(this.editaktUrl);
    },
    newUrlState() {
      var re = new RegExp(
        "(^$|[0-9a-zA-Z]([-.w]*[0-9a-zA-Z])*(:(0-9)*)*(/?)([a-zA-Z0-9-.?,'/+&amp;%$#_]*)?$)"
      );
      return re.test(this.editnewUrl);
    },
    stagingUrlState() {
      var re = new RegExp(
        "^[0-9a-zA-Z]([-.w]*[0-9a-zA-Z])*(:(0-9)*)*(/?)([a-zA-Z0-9-.?,'/+&amp;%$#_]*)?$"
      );
      return re.test(this.editstagingUrl);
    },

    loadingEditProject() {
      return this.projectstore.loadingEditProject;
    },

    loadingFetchUrls() {
      return this.newUrlstore.loadingNewUrls;
    },

    newUrlChanges() {
      var changed = false;

      if (
        (this.aktUrl === this.newUrl && this.editaktUrl !== this.editnewUrl) ||
        (this.aktUrl !== this.newUrl && this.editaktUrl === this.editnewUrl)
      ) {
        changed = true;
      }
      return changed;
    },
  },
  methods: {
    toGenerate() {
      if (!this.isGenerateOrControl) {
        if (
          this.store.urlsToRedirect.length == 0 ||
          window.localStorage.getItem("config_" + this.aktproject) !=
            undefined ||
          this.projectstore.aktProject.generated != undefined
        ) {
          this.router.push("/project/" + this.aktproject + "/generieren");
        } else {
          this.$refs["generate-modal"].show();
        }
      } else if (this.$route.name === "control") {
        this.router.push("/project/" + this.aktproject + "/generieren");
      }
    },
    goToGenerate() {
      this.$refs["generate-modal"].hide();
      window.localStorage.setItem("config_" + this.aktproject, "");
      this.router.push("/project/" + this.aktproject + "/generieren");
    },
    closeGenerateModal() {
      this.$refs["generate-modal"].hide();
    },

    onClickControl() {
      if (this.projectstore.aktProject.generated) {
        this.router.push("/project/" + this.aktproject + "/ueberpruefen");
      } else {
        this.shakeAnim = true;
        setTimeout(() => {
          this.shakeAnim = false;
        }, 1500);

        this.disabled = false;
      }
    },

    onMouseOverControl() {
      if (!this.projectstore.aktProject.generated) {
        this.disabled = false;
        this.show = true;
      } else {
        this.disabled = true;
      }
    },

    onMouseLeaveControl() {
      this.show = false;
    },

    async onFetchNew() {
      await this.newUrlstore.fetchNewUrlsNew(
        this.projectstore.aktProject.stagingUrl,
        this.projectstore.aktProject.proj_id
      );

      this.makeToast(
        this.projectstore.aktProject.proj_id,
        "Die URLs wurde erfolgreich erneuert."
      );
    },

    async onEditProject() {
      this.$refs["editprojectdata-modal"].show();

      // set aktUrl to edit
      this.editaktUrl = this.aktUrl;
      if (this.editaktUrl.includes("https://")) {
        this.editaktUrl = this.editaktUrl.replace("https://", "");
      } else if (this.editaktUrl.includes("http://")) {
        this.selected = "a";
        this.editaktUrl = this.editaktUrl.replace("http://", "");
      }

      // set newUrl to edit
      this.editnewUrl = this.newUrl;
      if (this.editnewUrl.includes("https://")) {
        this.editnewUrl = this.editnewUrl.replace("https://", "");
      }

      // set stagingUrl to edit
      this.editstagingUrl = this.stagingUrl;
      if (this.editstagingUrl.includes("https://")) {
        this.editstagingUrl = this.editstagingUrl.replace("https://", "");
      }

      // check newurl and akturl

      // if (this.edit) {
      // }

      // await this.store.updateConfigDB(this.projectstore.aktproject);
    },

    abortEditProject() {
      this.$refs["editprojectdata-modal"].hide();
    },

    async onSendForm() {
      //console.log("edit state=", this.projectstore.loadingEditProject);

      this.projectstore.loadingEditProject = true;
      // console.log("edit state=", this.projectstore.loadingEditProject);
      if (this.aktUrl === this.newUrl && this.editaktUrl !== this.editnewUrl) {
        let i = 0;

        await Promise.all(
          this.store.urlsDisabled.map(async (disabledurl) => {
            // this.store.urlsDisabled.forEach((disabledurl) => {
            await Promise.all(
              this.newUrlstore.newUrls.forEach(async (newurl) => {
                // check if disabled url is equal to one of newurl
                // console.log(sanitizeURL(disabledurl.url), sanitizeURL(newurl.url));
                if (
                  sanitizeURL(disabledurl.url).replace(
                    sanitizeURL(this.projectstore.aktProject.aktUrl),
                    ""
                  ) ===
                  sanitizeURL(newurl.url).replace(
                    sanitizeURL(this.projectstore.aktProject.stagingUrl),
                    ""
                  )
                ) {
                  // loop through all urls
                  await Promise.all(
                    this.store.urls.forEach(async (elem) => {
                      // check if url is disabled url
                      if (elem._id === disabledurl._id) {
                        // set disabled to false
                        elem.disabled.state = false;
                        elem.disabled.reason = "";
                        // set new url and add to redirectedUrls
                        elem.newUrl = newurl.url;
                        this.store.redirectedUrls.push(elem);

                        // remove url from disabled urls
                        this.store.urlsDisabled.splice(i, 1);

                        await this.store.updateDB(
                          this.projectstore.aktProject.proj_id,
                          elem._id
                        );
                      }
                    })
                  );
                }
              })
            );
            i++;
          })
        );
      } else if (
        this.aktUrl !== this.newUrl &&
        this.editaktUrl === this.editnewUrl
      ) {
        // same same
        let i = 0;
        await Promise.all(
          this.store.redirectedUrls.map(async (redirectedurls) => {
            // this.store.redirectedUrls.forEach((redirectedurls) => {
            await Promise.all(
              this.newUrlstore.newUrls.map(async (newurl) => {
                // check if disabled url is equal to one of newurl
                // console.log(sanitizeURL(redirectedurls.url), sanitizeURL(newurl.url));
                if (
                  sanitizeURL(redirectedurls.url).replace(
                    sanitizeURL(this.projectstore.aktProject.aktUrl),
                    ""
                  ) ===
                  sanitizeURL(newurl.url).replace(
                    sanitizeURL(this.projectstore.aktProject.stagingUrl),
                    ""
                  )
                ) {
                  // loop through all urls
                  await Promise.all(
                    this.store.urls.map(async (elem) => {
                      // check if url is disabled url
                      if (elem._id === redirectedurls._id) {
                        // set disabled to false
                        elem.disabled = [];
                        elem.disabled.state = true;
                        elem.disabled.reason =
                          "Url gibt es auch auf der neuen Seite.";
                        // set new url and add to redirectedUrls
                        elem.newUrl = "";
                        this.store.urlsDisabled.push(elem);

                        // console.log("slice", i);
                        // remove url from disabled urls
                        this.store.redirectedUrls.slice(i, 1);

                        await this.store.updateDB(
                          this.projectstore.aktProject.proj_id,
                          elem._id
                        );
                      }
                    })
                  );
                }
              })
            );
            i++;
          })
        );
      }

      //change staging url
      if (this.stagingUrl != this.editstagingUrl) {
        await this.newUrlstore.fetchNewUrlsNew(
          "https://" + this.editstagingUrl,
          this.projectstore.aktProject.proj_id
        );

        await Promise.all(
          this.store.urls.map(async (redirectedurls) => {
            if (
              redirectedurls.newUrl != "" &&
              redirectedurls.newUrl != undefined
            ) {
              // console.log(
              //   this.stagingUrl,
              //   this.editstagingUrl,
              //   redirectedurls.newUrl
              // );
              redirectedurls.newUrl.replace(
                this.stagingUrl,
                "https://" + this.editstagingUrl
              );

              // console.log(redirectedurls.newUrl);

              await this.store.updateDB(
                this.projectstore.aktProject.proj_id,
                redirectedurls._id
              );
            }
          })
        );

        this.projectstore.aktProject.stagingUrl =
          "https://" + this.editstagingUrl;
      }

      this.projectstore.aktProject.newUrl = "https://" + this.editnewUrl;

      this.store.updateConfigDB(this.projectstore.aktProject);
      this.projectstore.loadingEditProject = false;
      this.$refs["editprojectdata-modal"].hide();

      this.makeToast(
        this.projectstore.aktProject.proj_id,
        "Das Projekt wurde erfolgreich bearbeitet!"
      );
      function sanitizeURL(url) {
        let urlString = url;
        if (urlString.substring(urlString.length - 1) == "/") {
          urlString = urlString.substring(0, urlString.length - 1);
        }
        if (urlString.includes("https://")) {
          urlString = urlString.replace("https://", "");
        }
        if (urlString.includes("http://")) {
          urlString = urlString.replace("http://", "");
        }
        if (urlString.includes("www.")) {
          urlString = urlString.replace("www.", "");
        }

        return urlString;
      }

      // TODO: Update Config DB
      // TODO: validate stagingUrl.
    },
    openDelProject() {
      this.$refs["deleteproj-modal"].show();
    },
    onDeleteProject() {
      ProjectService.delete(this.projectstore.aktProject.proj_id).catch((e) => {
        console.log(e);
      });

      ConfigService.delete(this.projectstore.aktProject.id).catch((e) => {
        console.log(e);
      });

      let i = 0;
      this.projectstore.projects.forEach((elem) => {
        if (this.projectstore.aktProject.id === elem.id) {
          this.projectstore.projects.splice(i, 1);
        }
        i++;
      });

      this.router.push("/");
      this.$refs["deleteproj-modal"].hide();
      this.makeToast(
        this.projectstore.aktProject.proj_id,
        "Das Projekt wurde erfolgreich gelöscht!"
      );
    },
    closeDelProject() {
      this.$refs["deleteproj-modal"].hide();
    },

    openResetProject() {
      this.$refs["resetproj-modal"].show();
    },
    onResetProject() {
      this.store.resetProject(this.projectstore.aktProject);
    },
  },
  components: {},
  mounted() {
    //
  },
};
</script>
<style scoped></style>
