import http from "../axios-connect";
class ProjectService {
  getAll(project) {
    return http.get(`/${project}`);
  }
  getNewUrls(data) {
    return http.post(`/new-urls`, data);
  }
  checkStatuscode(data) {
    return http.post(`/statuscodes`, data);
  }
  create(data) {
    return http.post("/createcollection", data);
  }
  add(data) {
    return http.post("/addUrls", data);
  }
  addNewUrl(project, data) {
    return http.post(`/${project}/addnewdata`, data);
  }
  updateOne(project, id, data) {
    return http.post(`/${project}/${id}`, data);
  }
  delete(id) {
    return http.delete(`/${id}`);
  }
}
export default new ProjectService();
