import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginView.vue";
import AllProjects from "../views/AllProjects.vue";
import GenerateRed from "../views/GenerateRed.vue";
import ControlRed from "../views/ControlRed.vue";
import ErrPage404 from "../views/ErrorPage.vue";
import { createPinia, PiniaVuePlugin } from "pinia";

import { userStore } from "../store/user";

Vue.use(PiniaVuePlugin);
Vue.use(VueRouter);

const pinia = createPinia();

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "all",
    component: AllProjects,
    meta: { layout: "HomeMain", requiresAuth: true },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: { layout: "HomeMain" },
  },
  {
    path: "/project/:project",
    name: "home1",
    props: true,
    component: HomeView,
    meta: { layout: "HomeMain", requiresAuth: true },
    redirect: (to) => {
      return { path: "/project/:project/pflegen" };
    },
  },
  {
    path: "/project/:project/pflegen",
    name: "home",
    props: true,
    component: HomeView,
    meta: { layout: "HomeMain", requiresAuth: true },
  },
  {
    path: "/project/:project/generieren",
    name: "generate",
    props: true,
    component: GenerateRed,
    meta: { layout: "HomeMain", requiresAuth: true },
  },
  {
    path: "/project/:project/ueberpruefen",
    name: "control",
    props: true,
    component: ControlRed,
    meta: { layout: "HomeMain", requiresAuth: true },
  },
  {
    path: "/kunde/:project",
    name: "customer-home",
    props: true,
    component: HomeView,
    meta: { layout: "HomeKunde" },
  },
  {
    path: "/*",
    name: "ErrPage404",
    props: true,
    component: ErrPage404,
    meta: { layout: "HomeMain" },
  },
];

const router = new VueRouter({
  // mode: "history",
  // history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const userstore = userStore();

  if (localStorage.getItem("auth") == JSON.stringify(userstore.token)) {
    userstore.isAuth = true;
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (userstore.isAuth == true) {
      next();
      return;
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

export default router;
