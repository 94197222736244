import ConfigService from "../services/ConfigService";
import ProjectService from "../services/ProjectService";

import LocalForageHandler from "../components/db/lfHandler";

import { newUrlStore } from "./newUrls";
import { errorStore } from "./error";
import { defineStore } from "pinia";

export const projectStore = defineStore("projects", {
  state: () => ({
    projects: [],
    aktProject: [],

    loadingAllProjects: false,
    loadingCreateProject: false,
    stateCreateProjectText: "",
    stateCreateProjectValue: 0,
    loadingEditProject: false,
  }),
  getters: {
    getProjects(state) {
      return state.projects;
    },
  },

  actions: {
    async fetchProjects() {
      this.loadingAllProjects = true;
      this.projects = [];
      await ConfigService.getAll()
        .then((response) => {
          const data = response.data;

          data.forEach((elem) => {
            this.projects.push({
              id: elem._id,
              proj_id: elem.proj_id.toLowerCase(),
              aktUrl: elem.aktUrl,
              newUrl: elem.newUrl,
              stagingUrl: elem.stagingUrl,
              checked: elem.checked,
              generated: elem.generated,
              createdAt: elem.createdAt,
              updatedAt: elem.updatedAt,
              open: elem.open,
              success: elem.success,
            });
          });

          LocalForageHandler.setItems("config", this.projects);
        })
        .catch((e) => {
          console.log(e);
        });
      // }

      this.loadingAllProjects = false;
    },

    async addProject(config, formdata) {
      const errorstore = errorStore();

      if (errorstore.newProjectError == "") {
        // create config model
        const data = {
          proj_id: config.proj_id.toLowerCase(),
          aktUrl: removeTrailingSlash(config.aktUrl),
          newUrl: removeTrailingSlash(config.newUrl),
          stagingUrl: removeTrailingSlash(config.stagingUrl),
          open: "-1",
          createdAt: new Date(),
          updatedAt: new Date(),
        };

        // create project model

        // send config to database
        ConfigService.create(data).catch((e) => {
          console.log(e);
        });
        this.stateCreateProjectValue = 3;
        this.stateCreateProjectText =
          "3/5 Crawle nach URLs der Staging Seite...";
        // fetch new Urls
        const newUrlstore = newUrlStore();
        await newUrlstore.fetchNewUrlsFirst(config.stagingUrl, config.proj_id);

        this.stateCreateProjectValue = 4;
        this.stateCreateProjectText = "4/5 Vergleiche URLs";
        await delayInMs(1000);

        // check fileData and set to special urls to disabled
        formdata.forEach((elem) => {
          // if url contains mailto: or tel:
          if (this.errorstore)
            if (elem.url.includes("mailto:") || elem.url.includes("tel:")) {
              elem.disabled = {
                state: true,
                by: 0,
                reason: "Url enhält 'mailto' oder 'tel'. ",
              };
            }

          // FIXME: Check is not working yet
          // if url exits in new urls list

          newUrlstore.newUrls.forEach(async (item) => {
            let oldString = "";
            let newString = "";

            // sanitize urls
            oldString = sanitizeURL(elem.url);
            newString = sanitizeURL(item.url);

            if (
              oldString.replace(sanitizeURL(config.aktUrl), "") ==
              newString.replace(sanitizeURL(config.stagingUrl), "")
            ) {
              if (sanitizeURL(config.aktUrl) === sanitizeURL(config.newUrl)) {
                elem.disabled = {
                  state: true,
                  by: 0,
                  reason: "Url gibt es auch auf der neuen Seite.",
                };
              } else {
                elem.newUrl = item.url;
              }
            }
          });
        });
        this.stateCreateProjectValue = 5;
        this.stateCreateProjectText = "5/5 Lege Projekt final an.";
        await delayInMs(1000);

        // create Data for new Collection
        const projData = {
          proj_id: config.proj_id,
          fileData: formdata,
        };

        // add project to own collection
        ProjectService.create(projData).catch((e) => {
          console.log(e);
          console.log("sending is too large");
        });

        this.projects.push(data);
        this.loadingCreateProject = false;
        this.stateCreateProjectText = "";
        this.stateCreateProjectValue = -1;
      }

      function sanitizeURL(url) {
        let urlString = url;
        if (urlString.substring(urlString.length - 1) == "/") {
          urlString = urlString.substring(0, urlString.length - 1);
        }
        if (urlString.includes("https://")) {
          urlString = urlString.replace("https://", "");
        }
        if (urlString.includes("http://")) {
          urlString = urlString.replace("http://", "");
        }
        if (urlString.includes("www.")) {
          urlString = urlString.replace("www.", "");
        }

        return urlString;
      }

      function delayInMs(delayInms) {
        return new Promise((resolve) => setTimeout(resolve, delayInms));
      }

      function removeTrailingSlash(url) {
        // console.log(url);
        if (url !== undefined && url != "") {
          // console.log("removeTrailingSlash", url.replace(/\/?(\?|#|$)/, "$1"));
          return url.replace(/\/?(\?|#|$)/, "$1");
        } else {
          return url;
        }
      }
    },

    fetchAktConfig(proj_id) {
      this.aktProject = [];
      this.projects.forEach((elem) => {
        if (elem.proj_id == proj_id) {
          this.aktProject = elem;
        }
      });
    },
  },
});
