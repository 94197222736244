import { defineStore } from "pinia";

export const errorStore = defineStore("error", {
  state: () => ({
    errorOnRedirect: "",
    newProjectError: "",
    getEditProjectError: "",
    errorOnLogin: "",
    errorOnAddUrl: "",
  }),

  getters: {
    getErrorsOnRedirect() {
      return this.errorOnRedirect;
    },
    getNewProjectError() {
      return this.newProjectError;
    },
    getEditProjectError() {
      return this.newProjectError;
    },
    getErrorOnLogin() {
      return this.errorOnLogin;
    },
    getErrorOnAddUrl() {
      return this.errorOnAddUrl;
    },
  },

  actions: {},
});
