<template>
  <div class="allprojects">
    <div class="app-header">
      <b-container>
        <b-row>
          <b-col cols="12" md="6" offset="0" offset-md="1" class="col--1">
            <p>teufels - Weiterleitungs Tool</p>
            <h2>Weiterleitungen wie du <br />sie benötigst...</h2>
          </b-col>
          <b-col cols="12" md="4" offset="0" offset-md="1" class="col--2">
            <img src="../assets/intro-image.svg" />
          </b-col>
        </b-row>
      </b-container>
    </div>

    <b-container id="app">
      <b-row>
        <b-col cols="12" offset="0" md="10" offset-md="1">
          <div class="headline-main">
            <h2>
              <b-icon icon="house-fill"></b-icon><span>Alle Projekte</span>
            </h2>
            <b-button variant="secondary" v-on:click="openNewProject()"
              >Neues Projekt<b-icon icon="plus"></b-icon
            ></b-button>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" offset="0" md="10" offset-md="1">
          <b-card class="card__search">
            <b-card-text>
              <b-input-group>
                <b-input-group-prepend>
                  <b-icon icon="search"></b-icon>
                </b-input-group-prepend>

                <b-form-input
                  type="text"
                  v-model="search"
                  placeholder="Nach Projekt-ID suchen..."
                ></b-form-input>
                <!-- 
                <b-input-group-append>
                  <b-button>Suchen</b-button>
                </b-input-group-append> -->
              </b-input-group>
            </b-card-text>
          </b-card>
          <b-card class="card__projects">
            <div v-if="this.loadingProjects">
              <b-card-text>
                <b-alert class="alert__loading" show variant="secondary"
                  ><b-spinner
                    small
                    variant="light"
                    type="grow"
                    class="mr-3"
                    label="Busy"
                  ></b-spinner
                  >Projekte werden geladen...</b-alert
                >
              </b-card-text>
            </div>
            <div v-else>
              <b-card-text>
                <b-list-group>
                  <TransitionGroup name="list-all">
                    <b-list-group-item
                      v-for="col in filteredList"
                      :key="col.proj_id"
                      :to="'/project/' + col.proj_id"
                    >
                      <div class="project--state">
                        <span v-if="col.open >= 1">
                          {{ col.open }}
                          <b-icon icon="pencil-square"></b-icon>
                        </span>

                        <b-icon
                          icon="check2-square"
                          :class="{ green: col.success }"
                          v-if="col.open == 0"
                        ></b-icon>

                        <span v-if="col.open < 0"> NEU </span>

                        <!-- 
                        <b-icon
                          icon="pencil-square"
                          v-if="!col.checked && !col.generated"
                        ></b-icon>
                        <b-icon
                          icon="code-square"
                          v-if="!col.checked && col.generated"
                        ></b-icon>
                        <b-icon
                          icon="check2-square"
                          v-if="col.checked && col.generated"
                        ></b-icon> 
                        -->
                      </div>
                      <div>
                        <div class="projects--project_id">
                          {{ col.proj_id }}
                        </div>
                        <div class="projects--url">{{ col.stagingUrl }}</div>
                      </div>
                      <div class="projects--meta">
                        <div class="created">
                          <span class="meta-label">Erstellt:</span>
                          {{ formatDate(col.createdAt) }}
                        </div>
                        <div class="updated">
                          <span class="meta-label"> Zuletzt bearbeitet:</span>
                          {{ formatDate(col.updatedAt) }}
                        </div>

                        <div class="btn-overlay"></div>
                        <b-btn
                          class="open-btn"
                          variant="primary"
                          :to="'/project/' + col.proj_id"
                          >Projekt öffnen</b-btn
                        >
                      </div>
                    </b-list-group-item>
                  </TransitionGroup>
                </b-list-group>
                <b-alert variant="danger" show v-if="!filteredList.length > 0"
                  >Es wurde kein Projekt gefunden!</b-alert
                >
              </b-card-text>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <!-- ### NEW PROJECT MODAL ### -->
    <b-modal
      hide-footer
      show
      no-close-on-backdrop
      modal-class="modal__newproject"
      ref="newproject-modal"
    >
      <template #modal-title>
        <b-icon icon="plus"></b-icon>Neues Projekt anlegen
      </template>

      <b-alert
        v-if="errorstore.getNewProjectError !== ''"
        variant="danger"
        show
        v-html="this.errorstore.getNewProjectError"
      >
      </b-alert>

      <!-- Form for new Project -->
      <!-- FIXME: FORM VALIDATION -->
      <form v-if="!loadingCreateProject">
        <!-- ID - INPUT -->
        <b-form-group
          class="text-dark"
          id="fieldset-1"
          description="<Kundenkürzel>_<Jahr>_<ws/ms/os>"
          label="Project ID *"
          label-for="input-1"
          :state="this.idState"
          ><b-input-group>
            <b-form-input
              id="input-1"
              v-model="proj_id"
              placeholder="teu_22_ws"
              :state="this.idState"
              class=""
              trim
              :disabled="loadingCreateProject"
            ></b-form-input>
          </b-input-group>
        </b-form-group>

        <!-- AKT DOMAIN - INPUT -->
        <b-form-group
          class="text-dark"
          id="fieldset-1"
          label="Aktuelle Domain *"
          label-for="input-2"
          :state="this.aktUrlState"
        >
          <b-input-group>
            <template #prepend>
              <b-form-select
                v-model="selected"
                :options="options"
                :disabled="loadingCreateProject"
              ></b-form-select>
            </template>

            <b-form-input
              :state="this.aktUrlState"
              v-model="aktUrl"
              id="input-2"
              placeholder="teufels.com"
              :disabled="loadingCreateProject"
            ></b-form-input>
          </b-input-group>
        </b-form-group>

        <!-- LIVE DOMAIN - INPUT -->

        <b-form-group
          description="Leer lassen, falls Aktuelle Domain = Spätere Live-Domain"
        >
          <label class="text-dark" for="form-input-username"
            >Spätere Live-Domain</label
          >
          <b-input-group prepend="https://">
            <b-form-input
              :state="this.newUrlState"
              v-model="newUrl"
              id="form-input-username"
              placeholder="teufels.com"
              :disabled="loadingCreateProject"
            ></b-form-input>
          </b-input-group>
        </b-form-group>

        <!-- STAGING DOMAIN - INPUT -->
        <b-form-group
          ><label class="text-dark" for="form-input-username"
            >Staging-Domain *</label
          >
          <b-input-group prepend="https://">
            <b-form-input
              :state="this.stagingUrlState"
              v-model="stagingUrl"
              id="form-input-username"
              placeholder="staging.teufels.teufels-werk.de"
              :disabled="loadingCreateProject"
            ></b-form-input>
          </b-input-group>
        </b-form-group>

        <!-- OLD URL - UPLOAD -->
        <b-form-group
          description="Wenn du keine Datei hochlädst, werden die URLs automatisch gesucht."
        >
          <label class="text-dark" for="form-input-username"
            >URLs der aktuellen Domain (.csv)</label
          >

          <b-form-file
            v-model="form.file"
            :state="Boolean(form.file)"
            type="file"
            ref="doc"
            accept=".csv"
            placeholder="Datei auswählen..."
            drop-placeholder="Datei hier fallen lassen..."
            :disabled="loadingCreateProject"
          ></b-form-file>
        </b-form-group>

        <!-- <b-form-group>
          <b-form-checkbox v-model="crawl" size="md" name="auto-crawl" switch>
            URLs der aktuellen Domain automatisch crawlen
          </b-form-checkbox>
        </b-form-group> -->

        <p class="form-footnote">* Pflichtfeld</p>

        <div class="modal-buttons">
          <b-button @click="abortNewProject()" class="" variant="secondary">
            Abbrechen</b-button
          >

          <b-btn @click="onSendForm()" variant="primary"
            ><b-spinner
              v-if="loadingCreateProject"
              small
              variant="primary"
              type="grow"
              class="mr-3"
              label="Busy"
            ></b-spinner>
            <b-icon v-if="!loadingCreateProject" icon="plus"></b-icon>Projekt
            anlegen</b-btn
          >
        </div>
      </form>

      <div class="progress_bar" v-if="loadingCreateProject">
        <b-progress class="progress" animated :max="5" height="2rem">
          <b-progress-bar :value="stateCreateProjectValue"> </b-progress-bar>
        </b-progress>

        <span>{{ stateCreateProjectText }}</span>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { projectStore } from "../store/project.js";
import { errorStore } from "../store/error.js";
import { newUrlStore } from "../store/newUrls.js";
import router from "../router";
import moment from "moment";

export default {
  components: {},
  setup() {
    const projectstore = projectStore();
    const errorstore = errorStore();
    const newUrlstore = newUrlStore();

    return {
      projectstore,
      errorstore,
      newUrlstore,

      proj_id: "",
      aktUrl: "",
      newUrl: "",
      stagingUrl: "",
      crawl: false,

      form: {},

      selected: null,
      options: [
        { value: null, text: "https://" },
        { value: "a", text: "http://" },
      ],

      router,

      col: [],

      search: "",
    };
  },

  computed: {
    projects() {
      return this.projectstore.getProjects.slice().reverse();
    },
    loadingProjects() {
      return this.projectstore.loadingAllProjects;
    },
    loadingCreateProject() {
      return this.projectstore.loadingCreateProject;
    },
    stateCreateProjectText() {
      return this.projectstore.stateCreateProjectText;
    },
    stateCreateProjectValue() {
      return this.projectstore.stateCreateProjectValue;
    },
    filteredList() {
      return this.projects.filter((post) => {
        return post.proj_id.toLowerCase().includes(this.search.toLowerCase());
      });
    },

    idState: {
      get() {
        // return this.proj_id.length >= 3;
        var re = RegExp("^[a-zA-Z]+_[0-9]+_(ws|ms|os)$");
        return re.test(this.proj_id);
      },
    },
    aktUrlState() {
      var re = new RegExp("^(($|^(?!https?))[a-zA-Z0-9]+(.[a-zA-Z0-9]+)+.*)$");
      return re.test(this.aktUrl);
    },
    newUrlState() {
      var re = new RegExp(
        "^($|($|^(?!https?)[a-zA-Z0-9]+(.[a-zA-Z0-9]+)+.*))$"
      );
      return re.test(this.newUrl);
    },
    stagingUrlState() {
      var re = new RegExp("^(($|^(?!https?))[a-zA-Z0-9]+(.[a-zA-Z0-9]+)+.*)$");
      return re.test(this.stagingUrl);
    },
    fileCheck() {
      // return true;
      return this.form.file;
    },
  },
  methods: {
    openNewProject() {
      this.$refs["newproject-modal"].show();
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY, HH:mm [Uhr]");
    },

    async onSendForm() {
      this.errorstore.newProjectError = "";

      this.projectstore.projects.forEach((elem) => {
        if (this.proj_id.toLowerCase() === elem.proj_id.toLowerCase()) {
          this.errorstore.newProjectError =
            "Projekt ID ist schon vergeben.<br>";
        }
      });

      if (this.proj_id == "") {
        this.errorstore.newProjectError += "Bitte Projekt-ID angeben.<br>";
      } else if (this.idState == false) {
        this.errorstore.newProjectError +=
          "Bitte Projekt-ID richtig formatieren.<br>";
      }
      if (this.aktUrl == "") {
        this.errorstore.newProjectError += "Bitte Aktuelle Domain angeben.<br>";
      }

      // if (this.form.file == null && this.form.crawl != true) {
      //   this.errorstore.newProjectError +=
      //     "Bitte Urls der alten Domain hochladen (.csv).<br>";
      // }

      // else if (this.form.file.type != "text/csv") {
      //   this.errorstore.newProjectError +=
      //     "Bitte Urls der alten Domain als .csv hochladen.<br>";
      // }

      if (this.stagingUrl == "") {
        this.errorstore.newProjectError += "Bitte Staging Domain angeben.<br>";
      }
      // else if (!this.stagingUrl.includes("teufels-werk")) {
      //   this.errorstore.newProjectError +=
      //     "Bitte eine Staging-Domain von teufels-werk angeben.<br>";
      // }

      if (this.errorstore.newProjectError == "") {
        this.projectstore.loadingCreateProject = true;
        this.projectstore.stateCreateProjectValue = 1;
        this.projectstore.stateCreateProjectText =
          "1/5 Initiiere neues Projekt...";

        await this.delayInMs(1000);

        if (this.newUrl === "") {
          this.newUrl = this.aktUrl;
        }

        let prepend = "";

        if (this.selected == null) {
          prepend = "https://";
        } else {
          prepend = "http://";
        }

        const form = {
          proj_id: this.proj_id,
          aktUrl: prepend + this.aktUrl,
          newUrl: "https://" + this.newUrl,
          stagingUrl: "https://" + this.stagingUrl,
        };

        if (this.form.file != null) {
          this.projectstore.stateCreateProjectValue = 2;
          this.projectstore.stateCreateProjectText =
            "2/5 Lade .csv der URLs...";
          const content = await this.readFile(this.form.file);

          var array = content.toString().split("\n");

          // How to Convert CSV to JSON file having Comma Separated values in Node.js ?
          // https://www.geeksforgeeks.org/how-to-convert-csv-to-json-file-having-comma-separated-values-in-node-js/

          try {
            let result = [];

            let headers = array[0].split(",");

            for (let i = 1; i < array.length - 1; i++) {
              let obj = {};

              let str = array[i];
              let s = "";

              let flag = 0;
              for (let ch of str) {
                if (ch === '"' && flag === 0) {
                  flag = 1;
                } else if (ch === '"' && flag == 1) flag = 0;
                if (ch === "," && flag === 0) ch = "|";
                if (ch !== '"') s += ch;
              }

              let properties = s.split("|");

              for (let j in headers) {
                if (properties[j].includes(",")) {
                  obj[headers[j]] = properties[j]
                    .split(",")
                    .map((item) => item.trim());
                } else obj[headers[j]] = properties[j];
              }

              // Add the generated object to our result array
              result.push(obj);
            }

            // Convert the resultant array to json and generate the JSON output file.
            let json = JSON.parse(JSON.stringify(result));

            let final_arr = [];

            for (let item of json) {
              const input = {
                url: item['"Adresse"'],
              };

              final_arr.push(input);
            }

            await this.projectstore.addProject(form, final_arr);

            this.$refs["newproject-modal"].hide();
            this.abortNewProject();
          } catch (err) {
            console.log(err);
            this.errorstore.newProjectError +=
              "Die .csv Datei ist ungültig!<br>";
            this.errorstore.loadingCreateProject == false;
            this.projectstore.stateCreateProjectText = "";
            this.projectstore.stateCreateProjectValue = -1;
          }
        } else {
          // crawl urls
          this.projectstore.stateCreateProjectValue = 2;
          this.projectstore.stateCreateProjectText =
            "2/5 Crawle nach URLs auf der Live Seite...";
          console.log("aktUrl", form.aktUrl);
          await this.newUrlstore.fetchOldUrls(form.aktUrl);
          console.log(this.newUrlstore.oldUrls);
          await this.projectstore.addProject(form, this.newUrlstore.oldUrls);
          this.$refs["newproject-modal"].hide();
          this.abortNewProject();
        }
      }
    },

    abortNewProject() {
      this.proj_id = "";
      this.aktUrl = "";
      this.newUrl = "";
      this.stagingUrl = "";
      this.errorstore.newProjectError = "";
      this.$refs["newproject-modal"].hide();
    },

    readFile(input_file) {
      return new Promise((resolve) => {
        console.log("files", input_file);
        this.file = input_file;
        const reader = new FileReader();
        if (this.file.name.includes(".csv")) {
          reader.onload = (res) => {
            this.content = res.target.result;
            resolve(this.content);
          };
          reader.onerror = (err) => console.log(err);
          reader.readAsText(this.file);
        } else {
          this.content = "check the console for file output";
          reader.onload = (res) => {
            console.log(res.target.result);
          };
          reader.onerror = (err) => console.log(err);
          reader.readAsText(this.file);
        }
      });
    },
  },

  async created() {
    this.errorstore.errorOnRedirect = "";
    this.errorstore.newProjectError = "";
    this.errorstore.getEditProjectError = "";
    this.projectstore.loadingAllProjects = true;
    await this.projectstore.fetchProjects();
  },
};
</script>
