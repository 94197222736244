import axios from "axios";
export default axios.create({
  // baseURL: "http://localhost:4000/api",
  baseURL: "https://weiterleitungen-server.teufels-werk-2.de/api",
  // baseURL: process.env.VUE_APP_API_URI,

  headers: {
    "Content-type": "application/json",
  },
});
