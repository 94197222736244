<template>
  <div class="login">
    <div class="app-header">
      <b-container id="app">
        <b-row>
          <b-col cols="12" md="6" offset="0" offset-md="1" class="col--1">
            <p>teufels - Weiterleitungs Tool</p>
            <h2>Weiterleitungen wie du <br />sie benötigst...</h2>
          </b-col>
          <b-col cols="12" md="4" offset="0" offset-md="1" class="col--2">
            <img src="../assets/intro-image.svg" />
          </b-col>
        </b-row>
      </b-container>
    </div>

    <b-container id="app">
      <b-row>
        <b-col cols="12" offset="0" md="10" offset-md="1">
          <div class="headline-main">
            <h2><b-icon icon="house-fill"></b-icon><span>Login</span></h2>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" offset="0" md="7" xl="5" offset-md="1">
          <b-card class="card__login">
            <b-card-text>
              <b-alert
                v-if="errorstore.getErrorOnLogin !== ''"
                variant="danger"
                show
                v-html="this.errorstore.getErrorOnLogin"
              >
              </b-alert>

              <b-form @submit.prevent="onSendForm()">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-icon icon="person-circle"></b-icon>
                  </b-input-group-prepend>

                  <b-form-input
                    type="text"
                    v-model="username"
                    placeholder="Benutzername"
                  ></b-form-input>
                </b-input-group>

                <b-input-group>
                  <b-input-group-prepend>
                    <b-icon icon="lock-fill"></b-icon>
                  </b-input-group-prepend>

                  <b-form-input
                    type="password"
                    v-model="password"
                    placeholder="Passwort"
                    v-on:keyup.enter.prevent="onSendForm()"
                  ></b-form-input>
                </b-input-group>

                <b-input-group-append>
                  <b-button variant="primary" type="submit">Login</b-button>
                </b-input-group-append>
              </b-form>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { userStore } from "../store/user.js";
import { errorStore } from "../store/error.js";

export default {
  components: {},
  setup() {
    const userstore = userStore();
    const errorstore = errorStore();

    return {
      errorstore,
      userstore,

      username: "",
      password: "",
    };
  },

  computed: {
    // projects() {
    //   return this.projectstore.getProjects.slice().reverse();
    // },
  },
  methods: {
    async onSendForm() {
      this.userstore.authUser(this.username, this.password);
    },
  },
};
</script>
