<template>
  <div id="app">
    <b-alert show v-model="updateExists" class="alert-fixed" color="primary">
      Ein Update ist verfügbar.
      <b-btn text @click="refreshApp" variant="primary"> Aktualisieren </b-btn>
    </b-alert>
    <component :is="theme">
      <router-view />
    </component>
  </div>
</template>

<script>
import HomeMain from "./components/Home";
import HomeKunde from "./components/HomeKunde";
import update from "./mixins/update";
import { userStore } from "./store/user.js";

export default {
  setup() {
    const userstore = userStore();
    return {
      userstore,
    };
  },
  name: "App",
  mixins: [update],
  components: {
    HomeMain,
    HomeKunde,
  },
  computed: {
    theme() {
      return this.$route.meta.layout;
    },
  },
  mounted() {
    //
  },
  beforeMount() {
    this.userstore.authOnReload();
  },
};
</script>
