<template>
  <div class="home main errorpage">
    <b-container id="app">
      <b-row>
        <b-col offset="0" offset-md="2" cols="12" md="5">
          <img src="../assets/404-page.png"
        /></b-col>
        <b-col offset="0" cols="12" md="4">
          <h1>404</h1>
          <h2>Dieses Seite existiert leider nicht.</h2>

          <p>Komm mit, ich bring dich zurück...</p>

          <b-button variant="primary" :to="'/'"
            ><b-icon icon="arrow-left"></b-icon>Zur Startseite</b-button
          >
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import router from "../router";

export default {
  components: {},

  setup() {
    return {
      router,
    };
  },

  computed: {},
  methods: {},

  async created() {
    //
  },
};
</script>
