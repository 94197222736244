import { projectStore } from "./project.js";
import { useStore } from "./index.js";
import { defineStore } from "pinia";
import ProjectService from "../services/ProjectService";

export const newUrlStore = defineStore("newUrls", {
  state: () => ({
    newUrls: [],
    oldUrls: [],
    seenUrls: [],

    loadingNewUrls: false,
    fetchingOldUrl: false,
  }),
  getters: {
    getNewUrls(state) {
      return state.newUrls;
    },
    getProject: () => {
      const projectstore = projectStore();
      return projectstore.aktProject;
    },
    getStaging: () => {
      const projectstore = projectStore();
      return projectstore.aktProject.stagingUrl;
    },
  },

  actions: {
    // fetch the urls from domain and save it to localstorage by project id.
    async fetchNewUrlsFirst(urltocrawl, proj_id) {
      this.loadingNewUrls = true;

      if (window.localStorage.getItem("storedUrls_" + proj_id) !== null) {
        this.newUrls = await JSON.parse(
          window.localStorage.getItem("storedUrls_" + proj_id)
        );
      } else {
        await this.fetchNewUrlsAndUpdateDB(urltocrawl, proj_id);
      }

      this.newUrls = this.newUrls.sort((a, b) => {
        let retval = 0;
        if (a.url > b.url) retval = 1;
        if (a.url < b.url) retval = -1;
        if (retval === 0) retval = a.keyword < b.keyword ? 1 : -1;
        return retval;
      });

      this.loadingNewUrls = false;
    },

    async fetchNewUrlsNew(urltocrawl, proj_id) {
      this.loadingNewUrls = true;

      window.localStorage.removeItem("storedUrls_" + proj_id);

      await this.fetchNewUrlsAndUpdateDB(urltocrawl, proj_id);

      this.newUrls = this.newUrls.sort((a, b) => {
        let retval = 0;
        if (a.url > b.url) retval = 1;
        if (a.url < b.url) retval = -1;
        if (retval === 0) retval = a.keyword < b.keyword ? 1 : -1;
        return retval;
      });

      this.loadingNewUrls = false;
    },

    async fetchOldUrls(urltocrawl) {
      await ProjectService.getNewUrls({
        url: urltocrawl,
      })
        .then((response) => {
          var i = 0;
          this.oldUrls = [];
          response.data.forEach((elem) => {
            this.oldUrls.push({ url: elem.url });
            i++;
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async fetchOldUrlsAgain(aktUrl) {
      const store = useStore();

      await ProjectService.getNewUrls({
        url: aktUrl,
      })
        .then((response) => {
          response.data.forEach((elem) => {
            var found = false;
            store.urls.forEach((oldUrl) => {
              if (oldUrl.url === elem.url) {
                found = true;
              }
            });
            if (!found) {
              store.newFetchedAktUrls.push({ url: elem.url });
            }
          });
        })
        .catch((e) => {
          console.log(e);
        });

      this.fetchingOldUrl = false;
    },

    async fetchNewUrlsAndUpdateDB(urltocrawl, proj_id) {
      await ProjectService.getNewUrls({
        url: urltocrawl,
      })
        .then((response) => {
          var i = 0;
          this.newUrls = [];
          response.data.forEach((elem) => {
            this.newUrls.push({ id: i, url: elem.url });
            i++;
          });
        })
        .catch((e) => {
          console.log(e);
        });

      window.localStorage.setItem(
        "storedUrls_" + proj_id,
        JSON.stringify(this.newUrls)
      );
    },
  },
});
