import Vue from "vue";
import VueCompositionAPI from "@vue/composition-api";
import App from "./App.vue";
import router from "./router";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import { createPinia, PiniaVuePlugin } from "pinia";
import "./registerServiceWorker";
import VueOffline from "vue-offline";
import DoesExists from "./components/DoesExist.vue";
import ErrorState from "./components/ErrorState.vue";
import VueTour from "vue-tour";
import VueClipboard from "vue-clipboard2";

// import scss file
import "./scss/main.scss";
require("vue-tour/dist/vue-tour.css");

// register Components
Vue.component("does-exist", DoesExists);
Vue.component("error-state", ErrorState);

// create Pinia
const pinia = createPinia();

// ***
Vue.use(VueOffline);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueCompositionAPI);
Vue.use(PiniaVuePlugin);
Vue.use(VueTour);
Vue.use(VueClipboard);

Vue.config.productionTip = false;

// global small functions
Vue.mixin({
  methods: {
    makeToast(title, data) {
      this.$bvToast.toast(data, {
        title: title,
        autoHideDelay: 3000,
        appendToast: true,
        variant: "success",
        solid: true,
      });
    },
    delayInMs(delayInms) {
      return new Promise((resolve) => setTimeout(resolve, delayInms));
    },
  },
});

// ***
new Vue({
  pinia,
  router,
  render: (h) => h(App),
  el: "#app",
});
