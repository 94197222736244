import localforage from "localforage";

class LocalForageHandler {
  constructor() {
    localforage.config({
      name: "offlineData",
      version: 1.0,
      storeName: "projects",
    });
  }

  setItems(key: string, items: any) {
    localforage.setItem(key, items);
  }

  getItems(key: string) {
    return localforage.getItem(key);
  }
}
export default new LocalForageHandler();
