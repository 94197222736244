import ProjectService from "../services/ProjectService";
import ConfigService from "../services/ConfigService";
import { defineStore } from "pinia";
import { newUrlStore } from "./newUrls.js";

export const useStore = defineStore("urls", {
  state: () => ({
    urls: [],
    urlsToRedirect: [],
    urlsDisabled: [],
    redirectedUrls: [],
    newFetchedAktUrls: [],
    exists: false,
    loadingFetchUrls: false,
    loadingFetchAktDomain: false,
  }),

  getters: {
    getUrls(state) {
      return state.urls;
    },
    getUrlsToRedirect(state) {
      return state.urlsToRedirect;
    },

    getRedirectedUrls(state) {
      return state.redirectedUrls;
    },
  },

  actions: {
    // fetches url from database for specified project id
    async fetchUrls(project) {
      this.loadingFetchUrls = true;
      this.exists = false;
      try {
        await ProjectService.getAll(project)
          .then((response) => {
            if (!response.data.exists) {
              this.urls = response.data;

              this.urls.forEach((elem) => {
                if (!elem.checked) {
                  elem.checked = [];
                  elem.checked.code = "";
                  elem.checked.headers = [];
                }
              });

              // sort array by urls
              this.urls = this.urls.sort((a, b) => {
                let retval = 0;
                if (a.url > b.url) retval = 1;
                if (a.url < b.url) retval = -1;
                if (retval === 0) retval = a.keyword < b.keyword ? 1 : -1;
                return retval;
              });

              // set urls to redirect
              this.urlsToRedirect = this.urls.filter(
                (elem) =>
                  !elem.newUrl &&
                  (!elem.disabled || elem.disabled.state !== true)
              );

              // set disabled urls
              this.urlsDisabled = this.urls.filter(
                (elem) => elem.disabled && elem.disabled.state === true
              );

              // set redirected urls
              this.redirectedUrls = this.urls.filter(
                (elem) =>
                  elem.newUrl &&
                  elem.newUrl !== "" &&
                  (!elem.disabled || elem.disabled.state !== true)
              );
            } else {
              // send exists if projects does not exists
              this.exists = true;
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } catch (e) {
        alert(e);
      }
      this.loadingFetchUrls = false;
    },

    // updates project in db by id
    async updateDB(project, id) {
      this.urls.forEach((elem) => {
        if (id === elem._id) {
          ProjectService.updateOne(project, id, elem)
            .then((response) => {
              this.message = "The Project was updated successfully!";
            })
            .catch((e) => {
              console.log(e);
            });
        }
      });
    },

    // checks redirected url of specified project id
    // and saves statuscodes in db
    async checkRed(project) {
      this.redirectedUrls.forEach((elem) => {
        ProjectService.checkStatuscode({
          urls: [elem],
          project: project,
        }).catch((e) => {
          console.log(e);
        });
      });

      await this.fetchUrls(project.proj_id);

      let success = true;
      this.redirectedUrls.forEach((elem) => {
        if (elem.checked.code != "301" && elem.checked.code != "200") {
          success = false;
        }
      });

      project.success = success;
      project.checked = new Date();
      ConfigService.updateOne(project.id, project)
        .then((response) => {
          this.message = "The Config was updated successfully!";
        })
        .catch((e) => {
          console.log(e);
        });
    },

    // sets generated state for project
    // and saves generated redirects in db
    generateRed(project, redirects) {
      project.generated = {
        date: new Date(),
        redirects: redirects,
      };

      this.updateConfigDB(project);
    },

    // updates the config in db
    async updateConfigDB(project) {
      ConfigService.updateOne(project.id, project)
        .then((response) => {
          this.message = "The Config was updated successfully!";
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async addUrl(proj_id, url) {
      const data = {
        url: url,
      };

      ProjectService.addNewUrl(proj_id, data).catch((e) => {
        console.log(e);
        console.log("sending is too large");
      });

      await this.fetchUrls(proj_id);
    },

    async addNewFetchedUrl(proj_id, data) {
      ProjectService.addNewUrl(proj_id, data).catch((e) => {
        console.log(e);
        console.log("sending is too large");
      });

      await this.fetchUrls(proj_id);
    },

    async fetchAktDomain(url) {
      // TODO: implement feature
      const newurlstore = newUrlStore();

      await newurlstore.fetchOldUrlsAgain(url);

      // await this.fetchUrls(proj_id);
    },

    async resetProject(project) {
      // TODO: implement feature
    },
  },
});
